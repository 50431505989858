import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../core/models/api-response.model";

@Injectable({ providedIn: "root" })
export class BenefitService {
  private http = inject(HttpClient);
  private API_URL_PREFIX: string = environment.apiURL + "/admin/benefits";

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  // getAll(queryParams?: any) {
  //     let params = new HttpParams();
  //     if (queryParams) {
  //         for (const key in queryParams) {
  //             if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
  //                 params = params.set(key, queryParams[key]);
  //             }
  //         }
  //     }
  //     return this.http.get(this.API_URL_PREFIX, {
  //         headers: this.httpOptions.headers,
  //         params: params
  //     });
  // }

  getAllBenefits(status?: any): Observable<any> {
    let endpoint = this.API_URL_PREFIX;
    if (status) {
      endpoint += `?payment_status=${status.payment_status}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  getAll(proposalId?: any): Observable<any> {
    let endpoint = this.API_URL_PREFIX;
    if (proposalId) {
      endpoint += `?user_package_id=${proposalId}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  markAsPaid(payload: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.API_URL_PREFIX + "/pay", payload);
  }

  findById(id: string) {
    return this.http.get(
      this.API_URL_PREFIX + "/by-package/" + id,
      this.httpOptions
    );
  }
}
