<header id="page-topbar" data-scroll-header>
  <div class="layout-width">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box horizontal-logo">
          <a href="javascript:void(0);" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </a>

          <a href="javascript:void(0);" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="17" />
            </span>
          </a>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
          id="topnav-hamburger-icon"
          (click)="toggleMobileMenu($event)"
        >
          <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>
        <div class="ms-1 header-item d-none d-sm-flex">
          <span class="text-muted">{{ currentLoginBranch }}</span>
        </div>

        <!-- App Search-->
      </div>

      <div class="d-flex align-items-center">
        <div class="ms-1 header-item d-none d-sm-flex">
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-toggle="fullscreen"
            (click)="fullscreen()"
          >
            <i class="bx bx-fullscreen fs-22"></i>
          </button>
        </div>

        <div class="ms-1 header-item d-none d-sm-flex">
          <!-- <span class="text-muted">{{ currentLoginBranch }}</span> -->
        </div>

        <!-- <div ngbDropdown [autoClose]="false" class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button type="button" ngbDropdownToggle class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class='bx bx-bell fs-22'></i>
                        <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">3<span class="visually-hidden">unread messages</span></span>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">

                        <div class="dropdown-head bg-primary  bg-pattern rounded-top">
                            <div class="p-3">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                    </div>
                                    <div class="col-auto dropdown-tabs">
                                        <span class="badge bg-light-subtle text-body fs-13"> {{allnotifications?.length}} New</span>
                                    </div>
                                </div>
                            </div>

                            <div class="px-2 pt-2">
                                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs dropdown-tabs nav-tabs-custom" id="notificationItemsTab">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>
                                            All ({{allnotifications?.length}})
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                                                <ngx-simplebar style="max-height: 300px;">
                                                    <div class="pe-2">
                                                        @for(item of allnotifications;track $index){
                                                        <div class="text-reset notification-item d-block dropdown-item position-relative">
                                                            <div class=" d-flex">
                                                                @if(item.img){
                                                                <img src="{{item.img}}" class="me-3 rounded-circle avatar-xs" alt="user-pic">

                                                                <div class="flex-grow-1">
                                                                    <a href="javascript:void(0);" class="stretched-link">
                                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">{{item.title}}</h6>
                                                                    </a>
                                                                    <div class="fs-13 text-muted">
                                                                        <p class="mb-1">{{item.desc}}</p>
                                                                    </div>
                                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span><i class="mdi mdi-clock-outline"></i> {{item.time}}</span>
                                                                    </p>
                                                                </div>
                                                                }@else{
                                                                <div class="avatar-xs me-3">
                                                                    <span class="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                                        <i class="bx {{item.icon}}"></i>
                                                                    </span>
                                                                </div>
                                                                <div class="flex-grow-1">
                                                                    <a href="javascript:void(0);" class="stretched-link">
                                                                        <h6 class="mt-0 mb-2 lh-base">{{item.desc}}
                                                                        </h6>
                                                                    </a>
                                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span><i class="mdi mdi-clock-outline"></i>{{item.time}}</span>
                                                                    </p>
                                                                </div>}


                                                                <div class="px-2 fs-15">
                                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="item.state" (change)="onCheckboxChange($event,'1')" value="{{item.id}}">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        @if(allnotifications?.length > 0){
                                                        <div class="my-3 text-center">
                                                            <button type="button" class="btn btn-soft-success waves-effect waves-light">View
                                                                All Notifications <i class="ri-arrow-right-line align-middle"></i></button>
                                                        </div>
                                                        }
                                                    </div>

                                                    <div [ngClass]="allnotifications?.length != 0 ?'d-none':''" class="tab-pane p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                                                        <div class="w-25 w-sm-50 pt-3 mx-auto">
                                                            <img src="assets/images/svg/bell.svg" class="img-fluid" alt="user-pic">
                                                        </div>
                                                        <div class="text-center pb-5 mt-2">
                                                            <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any
                                                                notifications </h6>
                                                        </div>
                                                    </div>
                                                </ngx-simplebar>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink>
                                            Messages
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                                                <ngx-simplebar style="max-height: 300px;">
                                                    <div class="pe-2">
                                                        @for(message of messages;track $index){
                                                        <div class="text-reset notification-item d-block dropdown-item">
                                                            <div class="d-flex">
                                                                <img src="{{message.avatar}}" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                                                <div class="flex-grow-1">
                                                                    <a href="javascript:void(0);" class="stretched-link">
                                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">{{message.name}}</h6>
                                                                    </a>
                                                                    <div class="fs-13 text-muted">
                                                                        <p class="mb-1">{{message.message}}</p>
                                                                    </div>
                                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span><i class="mdi mdi-clock-outline"></i> {{message.time_ago}}</span>
                                                                    </p>
                                                                </div>
                                                                <div class="px-2 fs-15">
                                                                    <input class="form-check-input" type="checkbox" value="{{message.id}}" [(ngModel)]="message.state" (change)="onCheckboxChange($event,'2')" id="{{message.checkboxId}}">
                                                                    <label class="form-check-label" for="{{message.checkboxId}}"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        @if(messages?.length > 0){
                                                        <div class="my-3 text-center">
                                                            <button type="button" class="btn btn-soft-success waves-effect waves-light">View
                                                                All Messages <i class="ri-arrow-right-line align-middle"></i></button>
                                                        </div>
                                                        }
                                                    </div>

                                                    <div [ngClass]="messages?.length != 0 ?'d-none':''" class="tab-pane p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                                                        <div class="w-25 w-sm-50 pt-3 mx-auto">
                                                            <img src="assets/images/svg/bell.svg" class="img-fluid" alt="user-pic">
                                                        </div>
                                                        <div class="text-center pb-5 mt-2">
                                                            <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any
                                                                notifications </h6>
                                                        </div>
                                                    </div>
                                                </ngx-simplebar>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="3">
                                        <a ngbNavLink>
                                            Alerts
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="tab-pane p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                                                <div class="w-25 w-sm-50 pt-3 mx-auto">
                                                    <img src="assets/images/svg/bell.svg" class="img-fluid" alt="user-pic">
                                                </div>
                                                <div class="text-center pb-5 mt-2">
                                                    <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any
                                                        notifications </h6>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <div class="tab-content" id="notificationItemsTabContent">
                            <div class="tab-content text-muted">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>

                        <div class="notification-actions" id="notification-actions">
                            <div class=" d-flex text-muted justify-content-center">
                                Select <div id="select-content" class="text-body fw-semibold px-1">{{checkedValGet.length}}</div> Result <button type="button" class="btn btn-link link-danger p-0 ms-3" data-bs-toggle="modal" (click)="openModal(removenotification)" data-bs-target="#removeNotificationModal">Remove</button>
                            </div>
                        </div>
                    </div>
                </div> -->

        <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
          <button
            type="button"
            class="btn"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <span class="d-flex align-items-center">
              @if(this.selectedImage){
              <img
                class="rounded-circle header-profile-user-size"
                [src]="this.selectedImage"
                alt="Header Avatar"
              />
              }@else {
              <div
                class="d-flex justify-content-center align-items-center"
                style="
                  width: 40px;
                  height: 40px;
                  background-color: #0a3d2c;
                  color: whitesmoke;
                  border-radius: 50%;
                "
              >
                {{ userDisplayName[0] | capitalize }}
              </div>
              }
              <span class="text-start ms-xl-2">
                <span
                  class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                >
                  {{ userDisplayName | capitalize }}</span
                >
                <span
                  class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"
                  >{{ userDisplayRole | capitalize }}</span
                >
              </span>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <h6 class="dropdown-header">
              {{ "Welcome " + (userDisplayName | capitalize) + "!" }}
            </h6>
            <a
              *ngIf="switchBranchVisible"
              style="cursor: pointer"
              class="dropdown-item"
              (click)="backToSwitchBranch()"
              ><i
                class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"
              ></i>
              <span class="align-middle">Switch Branch</span></a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/profile"
              ><i
                class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"
              ></i>
              <span class="align-middle">Profile</span></a
            >
            <!-- <a class="dropdown-item" routerLink="/auth/lockscreen/basic"><i class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Lock screen</span></a> -->
            <a
              class="dropdown-item"
              href="javascript: void(0);"
              (click)="logout()"
              ><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle" data-key="t-logout">Logout</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- removeNotificationModal -->
<ng-template #removenotification let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        id="NotificationModalbtn-close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <div class="mt-2 text-center">
        <lord-icon
          src="https://cdn.lordicon.com/gsqxdxog.json"
          trigger="loop"
          colors="primary:#f7b84b,secondary:#f06548"
          style="width: 100px; height: 100px"
        ></lord-icon>
        <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
          <h4>Are you sure ?</h4>
          <p class="text-muted mx-4 mb-0">
            Are you sure you want to remove this Notification ?
          </p>
        </div>
      </div>
      <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
        <button
          type="button"
          class="btn w-sm btn-light"
          data-bs-dismiss="modal"
          (click)="modal.dismiss('Cross click')"
        >
          Close
        </button>
        <button
          type="button"
          class="btn w-sm btn-danger"
          id="delete-notification"
          (click)="notificationDelete()"
        >
          Yes, Delete It!
        </button>
      </div>
    </div>
  </div>
  <!-- /.modal-content -->
</ng-template>
