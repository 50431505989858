import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisallowMinus]'
})
export class DisallowMinusDirective {

  constructor() { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Prevent the minus sign (-) key
    if (event.key === '-' || event.key === '+' || event.key === 'e') {
      event.preventDefault();
    }
  }
}



