<app-breadcrumbs
  title="Role Permissions"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header custom-tr">
                <div class="row">
                    <div class="col-6">                        
                        <input type="text" class="form-control" [(ngModel)]="searchValue" (input)="filterRequests()" id="txtsearch" placeholder="Search..." >
                    </div>
                    <div class="col-6 text-end">
                        <button
                        type="button"
                        class="btn btn-primary waves-effect waves-light"
                        data-bs-toggle="modal"
                        data-bs-target="#rolecontentModal"
                        data-bs-whatever="Mary"
                      >
                        Add New Role
                      </button>
                    </div>
                </div>
              
                
            </div><!-- end card header -->
  
            <div class="card-body">
                <p-table
                [value]="filteredRequests"
                styleClass="p-datatable-striped"
                [paginator]="true"
                [rows]="10"
                [showCurrentPageReport]="true"
                [tableStyle]="{ 'min-width': '50rem' }"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[5, 10, 20]"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:20%">Name</th>
                        <th style="width:20%">Status</th>
                        <th style="width:20%">Permissions</th>
                        <th style="width:20%">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-request>
                    <tr>
                        <td>{{request.name}}</td>
                        <td>{{request.name}}</td>
                        <td>
                            <span>
                                {{request.country.name}}
                            </span>
                        </td>
                        <td>
                            <button type="button" class="btn btn-primary btn-sm bg-gradient waves-effect waves-light"
                             [routerLink]="['/role-permission/permissions',1]" >View</button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                    <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
                </ng-template>
                <ng-template pTemplate="paginatorright">
                    <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
                </ng-template>
            </p-table>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->