import { Component, ElementRef, inject, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PlanService } from 'src/app/services/plans.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpErrorResponse } from '@angular/common/http';
import { values } from 'lodash';
import { ErrorComponent } from 'src/app/shared/components/errorHandle/error/error.component';



@Component({
  selector: 'app-edit-plan',
  standalone: true,
  imports: [CommonModule, SharedModule, NgSelectModule, FormsModule, ReactiveFormsModule, ErrorComponent],
  templateUrl: './edit-plan.component.html',
  styleUrl: './edit-plan.component.scss'
})
export class EditPlanComponent {

  breadCrumbItems!: Array<{}>;
  plansList: any;
  planDetails: any;
  planUpdateForm!: FormGroup;
  planTemplates: any;
  isSubmitted: boolean = false;
  submittedData: boolean = false;
  selectedPlanTemplate: any;
  planId: any;
  years: number = 1;
  isDisabled: boolean = false;

  formErrors: { key: string, value: string }[] = [];

  private planService = inject(PlanService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private ngxLoader = inject(NgxUiLoaderService);

  @ViewChildren('planNameError,minimumAmountError') errorElements!: QueryList<ElementRef>

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Plans" },
      { label: "Create", active: true },
    ];

    this.planId = this.route.snapshot.paramMap.get('id');
    this.getPlanTemplates();
    this.getPlanDetails(this.planId);
    this.planUpdateForm = new FormGroup({
      planTemplate: new FormControl({ value: '', disabled: true }),
      name: new FormControl("", [Validators.required]),
      investmentAmount: new FormControl("", [Validators.required]),
      duration: new FormControl({ value: '', disabled: true }),
      yearBenefits: new FormArray([]),
      profitPerMonth: new FormControl({ value: '', disabled: true }),
      benefitPerYear: new FormControl({ value: '', disabled: true })
    });
  }

  getPlanTemplates() {
    this.ngxLoader.start();
    this.planService.getPlanTemplates().subscribe((response: any) => {
      console.log(response);
      this.planTemplates = response.data;
      this.ngxLoader.stop();
    });
  }

  getPlanDetails(planId: any) {
    // this.ngxLoader.start();
    this.ngxLoader.start();
    this.scrollToFirstErrorNonApiCall();
    this.planService.findById(planId).subscribe((response: ApiResponse) => {
      this.planDetails = response.data;
      // console.log(this.product.product.productAdd);
      this.years = this.planDetails.interest_rates.length;
      this.selectedPlanTemplate = this.planDetails.plan_template;



      //   response.data.product.files.forEach((file: any) => {
      //     this.images.push({ id: file.file.id, path: file.file.path });
      //   });
      //   // this.productUpdateForm.value.items = this.product.product.productAdd;

      if (this.selectedPlanTemplate.id == 3) {
        this.planUpdateForm.patchValue({
          planTemplate: this.planDetails.plan_template,
          name: this.planDetails.name,
          investmentAmount: this.planDetails.minimum_amount,
          duration: this.planDetails.duration,
          profitPerMonth: this.planDetails.profit_per_month,
          benefitPerYear: this.planDetails.benefit_per_month,
        });
      }

      if (this.selectedPlanTemplate.id == 1 || this.selectedPlanTemplate.id == 2) {

        let itemsForm = <FormArray><unknown>this.planUpdateForm.controls['yearBenefits'];
        this.planDetails.interest_rates.forEach((element: any) => {
          itemsForm.push(new FormGroup({
            // title: new FormControl(element.title),
            benefit: new FormControl({ value: element.rate, disabled: true }),
          }));
        });

        this.planUpdateForm.patchValue({
          planTemplate: this.planDetails.plan_template,
          name: this.planDetails.name,
          investmentAmount: this.planDetails.minimum_amount,
          duration: this.planDetails.duration,
        });
      }

      if (this.selectedPlanTemplate.id == 4) {
        this.planUpdateForm.patchValue({
          planTemplate: this.planDetails.plan_template,
          name: this.planDetails.name,
          investmentAmount: this.planDetails.minimum_amount,
          duration: this.planDetails.duration,
          benefitPerYear: this.planDetails.benefit_per_month,

        });
      }
      this.ngxLoader.stop();

    });
    // this.ngxLoader.stop();
  }

  onPlanTemplateChange(event: any) {
    this.isSubmitted = false;
    this.selectedPlanTemplate = this.planTemplates.find((template: any) => template.id === event.id);
    this.setConditionalValidators();
  }

  setConditionalValidators() {
    const profitPerMonthControl = this.planUpdateForm.get('profitPerMonth');
    const benefitPerYearControl = this.planUpdateForm.get('benefitPerYear');

    if (this.selectedPlanTemplate?.name === 'Percentage + Profit + Capital') {
      profitPerMonthControl?.setValidators([Validators.required]);
      benefitPerYearControl?.setValidators([Validators.required]);
    } else {
      profitPerMonthControl?.clearValidators();
      benefitPerYearControl?.clearValidators();
    }

    profitPerMonthControl?.updateValueAndValidity();
    benefitPerYearControl?.updateValueAndValidity();
  }

  onDurationChange(event: any) {
    console.log("duration", event);
    const duration = +event.target.value;


    this.generateYearInputs(duration);
  }

  getControls() {
    return (this.planUpdateForm.get("yearBenefits") as FormArray).controls;
  }

  generateYearInputs(duration: number) {
    // const yearBenefits = this.planUpdateForm.get('yearBenefits') as FormArray;
    // yearBenefits.clear();  // Clear existing inputs
    this.years = Math.ceil(duration / 12);

    const form = <FormArray>(<unknown>this.planUpdateForm.controls["yearBenefits"]);
    form.clear();
    for (let i = 0; i < this.years; i++) {
      // yearBenefits.push(new FormControl("", Validators.required));
      form.push(this.addItemSubForm());
    }
  }

  addItemSubForm() {
    return new FormGroup({
      benefit: new FormControl("", [Validators.required]),
    });
  }
  cancelOnAction() {
    this.router.navigateByUrl('/plans')
  }

  // check the error key after boolean
  hasErrorFormCheck(key: string): boolean {
    return this.formErrors.some((err => err.key === key)); // check only boolean
  }

  //filter the form array and check
  filterErrorsByKey(key: string): { key: string, value: string }[] {
    return this.formErrors.filter(error => error.key === key); // return new array
  }

  scrollToFirstErrorNonApiCall() {
    setTimeout(() => {
      const firstErrorElements = this.errorElements.find(el => {
        return el.nativeElement.offsetHeight > 0 && !!el.nativeElement.innerHTML.trim();
      });

      if (firstErrorElements) {
        firstErrorElements.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }

  /* scroll to error area  api call*/
  scrollToFirstError() {
    setTimeout(() => {
      const firstErrorElement = this.errorElements.find(el => {
        return !!el.nativeElement.innerHTML.trim();
      });

      if (firstErrorElement) {
        firstErrorElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }


  EditPlan() {

    this.isSubmitted = true;
    this.scrollToFirstErrorNonApiCall();
    if (this.planUpdateForm.valid) {
      this.submittedData = true;
      this.ngxLoader.start();
      let payload = {};
      // if (this.selectedPlanTemplate?.id === 3) {
      payload = {
        plan_template_id: this.selectedPlanTemplate.id,
        name: this.planUpdateForm.value.name,
        minimum_amount: this.planUpdateForm.value.investmentAmount,
      };

      this.planService.update(this.planId, payload).subscribe({
        next: (res: any) => {
          this.submittedData = false;
          this.isSubmitted = false;
          this.planUpdateForm.reset();
          this.toastr.success(res.message ? res.message : "Plan updated successfully", 'Success');
          this.ngxLoader.stop();
          this.router.navigate(['/plans']);
        },
        error: (error: HttpErrorResponse) => {
          this.isSubmitted = false;
          this.submittedData = false;
          this.formErrors = [];
          if (error.status === 422 && error.error) {
            console.log('employee add error', error.error);

            for (const [key, value] of Object.entries(error?.error)) {
              if (typeof value === 'object' && value != null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                  this.formErrors.push({ key: nestedKey, value: nestedValue as string });
                  this.submittedData = false;
                }
                this.scrollToFirstError();
              } else {
                this.formErrors.push({ key: key, value: value as string })
                this.submittedData = false;
                this.scrollToFirstError();
              }
            }
          } else {
            this.toastr.error(error.message || "Something went wrong, Please try again later", "Error!");
          }
          /*   let message = error?.message;
            if (error.status == 422) {
              const firstKey = Object.keys(error.error)[0];
              message = error.error[firstKey];
            } */
          this.ngxLoader.stop();
          //   this.toastr.error(message, "error!");
        },
      });
    }
  }

  getOrdinalSuffix(i: number): string {
    const j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
}
