import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

  constructor() { }

  // HostListener to listen for keypress events
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];

    // Allow special keys like backspace, tab, and arrow keys
    if (allowedKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Prevent non-numeric key presses
    if ((event.key < '0' || event.key > '9')) {
      event.preventDefault();
    }
  }

  // Input validation to prevent pasting non-numeric characters
  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    const cleanedValue = inputElement.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    inputElement.value = cleanedValue;
  }
}
