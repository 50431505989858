import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../core/models/api-response.model";


@Injectable({ providedIn: 'root' })
export class UserProfileService {

    private http = inject(HttpClient);
    private API_URL_PREFIX: string = environment.apiURL + '/admin'


    private httpOptions = {
        headers: new HttpHeaders({ 'Content-type': 'application/json' })
    }

    updateProfile(payload: object) {
        return this.http.patch<ApiResponse>(this.API_URL_PREFIX + '/user', payload, this.httpOptions);
    }
    getProfileDetail() {
        return this.http.get<ApiResponse>(this.API_URL_PREFIX + '/user');
    }

    passwordReset(payload: Object) {
        return this.http.patch<ApiResponse>(this.API_URL_PREFIX + '/user-profile/password', payload, this.httpOptions);
    }


}