import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { TableModule } from 'primeng/table';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, NgModel } from '@angular/forms';
import { TagModule } from 'primeng/tag';
import { PaymentService } from 'src/app/services/payment.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-receipt',
  standalone: true,
  imports: [CommonModule, FeatherModule, TableModule, NgSelectModule, SharedModule, FormsModule, TagModule],
  templateUrl: './receipt.component.html',
  styleUrl: './receipt.component.scss'
})
export class ReceiptComponent implements OnInit {

  receiptList: any;
  isDisplay: any;
  filterRequest: any
  searchValue: any;
  requestData: any;
  isReceptionist: any;
  isReceiptPackages: any;

  private receipt = inject(PaymentService);
  private modalService = inject(NgbModal);
  private ngxLoader = inject(NgxUiLoaderService);


  ngOnInit(): void {
    this.getAllReceipt();
  }


  getAllReceipt() {
    this.ngxLoader.start();
    this.receipt.findAll().subscribe((resp: any) => {
      this.receiptList = resp.data;
      this.filterRequest = resp.data;
      this.ngxLoader.stop();
    });
  }

  filterTheReceipt() {
    this.filterRequest = this.receiptList.filter((request: any) => {

      return (
        request.receipt_number?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        request.ref_number?.toLowerCase().includes(this.searchValue.toLowerCase())
      )
    })
  }
  openReceiptModal(request: any, receiptDetailModal: any) {
    this.modalService.open(receiptDetailModal); // open modal
    this.isReceptionist = request;
    this.findByIdPackage(request.user_package_id);
    //console.log('userPackage id', request.user_package_id);
  }

  findByIdPackage(id: string) {
    console.log('empty id', id);
    this.ngxLoader.start();
    this.receipt.findById(id).subscribe((resp: any) => {
      this.isReceiptPackages = resp.data;
      //console.log('findByPackage----', resp.data);
      this.ngxLoader.stop();
    });
  }
}
