import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RolesComponent } from './role-permission/roles/roles.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from '../core/interceptors/error.interceptor';
import { PermissionsComponent } from './role-permission/permissions/permissions.component';
import { AllDesignationsComponent } from './designations/all-designations/all-designations.component';
import { HierarchyListComponent } from './admin-hierarchy/hierarchy-list/hierarchy-list.component';
import { AllPlansComponent } from './plans/all-plans/all-plans.component';
import { AddPlanComponent } from './plans/add-plan/add-plan.component';
import { EditPlanComponent } from './plans/edit-plan/edit-plan.component';
import { AllComponent } from './quotation/all/all.component';
import { AddComponent } from './quotation/add/add.component';
import { BenefitComponent } from './benefit/benefit.component';
import { PaymentComponent } from './payment/payment.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { SwitchBranchComponent } from './switch-branch/switch-branch.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { CustomerAudienceComponent } from './customer-audience/customer-audience/customer-audience.component';


// Component pages

const routes: Routes = [

  {
    path: "",
    component: DashboardComponent
  },
  // {
  //   path: "roles",
  //   component: RolesComponent
  // },
  {
    path: "role-permission",
    children: [
      {
        path: "roles",
        component: RolesComponent,
      },
      {
        path: "permissions/:id",
        component: PermissionsComponent,
      },

    ]
  },
  {
    path: "designations",
    component: AllDesignationsComponent
  },
  {
    path: "admin-hierarchy",
    component: HierarchyListComponent
  },
  {
    path: "plans",
    children: [
      {
        path: "",
        component: AllPlansComponent,
      },
      {
        path: "add",
        component: AddPlanComponent,
      },
      {
        path: "edit/:id",
        component: EditPlanComponent,
      },

    ]
  },
  {
    path: 'employees',
    loadChildren: () => import('./employee/employee.routes').then(m => m.EMPLOYEE_ROUTES)
  },
  {
    path: "quotations",
    children: [
      {
        path: "",
        component: AllComponent
      },
      {
        path: "add",
        component: AddComponent,
      },

    ]
  },
  {
    path: "benefits",
    children: [
      {
        path: "",
        component: BenefitComponent
      },
    ]
  },
  {
    path: "payments",
    children: [
      {
        path: "",
        component: PaymentComponent
      },

    ]
  },
  {
    path: "receipts",
    component: ReceiptComponent
  },
  {
    path: 'proposals',
    loadChildren: () => import('./proposal/proposal.routes').then(m => m.PROPOSAL_ROUTES)
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'customer-audience',
    component: CustomerAudienceComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },]
})
export class PagesRoutingModule { }
