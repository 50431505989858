import { Component, inject, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PlanService } from 'src/app/services/plans.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorComponent } from 'src/app/shared/components/errorHandle/error/error.component';

@Component({
  selector: 'app-add-plan',
  standalone: true,
  imports: [CommonModule, SharedModule, NgSelectModule, FormsModule, ReactiveFormsModule, ErrorComponent],
  templateUrl: './add-plan.component.html',
  styleUrl: './add-plan.component.scss'
})
export class AddPlanComponent {

  breadCrumbItems!: Array<{}>;
  plansList: any;
  planAddForm!: FormGroup;
  planTemplates: any;
  planTemplate: any;
  isSubmitted: boolean = false;
  selectedPlanTemplate: any;
  submittedData: boolean = false;
  isDurationDisabled: boolean = false;
  formErrors: { key: string, value: string }[] = []; // form error array
  commissionRateTopicView: boolean = false;


  durationZeroValidation: boolean = false;

  private planService = inject(PlanService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private ngxLoader = inject(NgxUiLoaderService);


  @ViewChildren('planTemplateError,planNameError,planMinimumAmountError,planDurationError') errorElements!: QueryList<ElementRef>;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Plans" },
      { label: "Create", active: true },
    ];
    this.getPlanTemplates();
    this.planAddForm = new FormGroup({
      planTemplate: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      minimumAmount: new FormControl("", [Validators.required]),
      duration: new FormControl({ value: "", disabled: true }, [Validators.required]),
      yearBenefits: new FormArray([]),
      profitPerMonth: new FormControl(""),
      benefitPerYear: new FormControl("")
    });
  }

  getPlanTemplates() {
    this.ngxLoader.start();
    this.planService.getPlanTemplates().subscribe((response: any) => {
      console.log(response);
      this.planTemplates = response.data;
      this.ngxLoader.stop();
    });
  }

  inputOnChange(event: any) {
    const inputValue = event.target.value;

    if (/^0+$/.test(inputValue)) {
      this.durationZeroValidation = true;
      this.planAddForm.controls['duration'].setErrors({ nonZero: true }) // can manually set errors
      this.isSubmitted = false;
      this.commissionRateTopicView = false;
    } else if (inputValue == '') {
      this.commissionRateTopicView = false;
      this.durationZeroValidation = false;
    } else {
      this.durationZeroValidation = false;
      this.commissionRateTopicView = true;
      this.planAddForm.controls['duration'].setErrors(null);
    }
  }

  onPlanTemplateChange(event: any) {

    this.isSubmitted = false;
    this.selectedPlanTemplate = this.planTemplates.find((template: any) => template.id === event?.id);
    if(this.selectedPlanTemplate) {
      this.isDurationDisabled = false; // Enable the duration field when a plan template is selected
      this.planAddForm.get('duration')?.enable(); // Explicitly enable the duration field
    } else {
      this.isDurationDisabled = true; // Disable the duration field if no plan template is selected
      this.planAddForm.get('duration')?.disable(); // Explicitly disable the duration field
    }
    this.setConditionalValidators();
  }

  setConditionalValidators() {
    const profitPerMonthControl = this.planAddForm.get('profitPerMonth');
    const benefitPerYearControl = this.planAddForm.get('benefitPerYear');

    if (this.selectedPlanTemplate?.id === 3) {
      profitPerMonthControl?.setValidators([Validators.required]);
      benefitPerYearControl?.setValidators([Validators.required]);
    }
    else if (this.selectedPlanTemplate?.id === 4) {
      benefitPerYearControl?.setValidators([Validators.required]);
    }
    else {
      profitPerMonthControl?.clearValidators();
      benefitPerYearControl?.clearValidators();
    }

    profitPerMonthControl?.updateValueAndValidity();
    benefitPerYearControl?.updateValueAndValidity();
  }

  onDurationChange(event: any) {
    console.log("duration", event);
    const duration = +event.target.value;
    this.generateYearInputs(duration);
  }

  getControls() {
    return (this.planAddForm.get("yearBenefits") as FormArray).controls;
  }

  generateYearInputs(duration: number) {
    // const yearBenefits = this.planAddForm.get('yearBenefits') as FormArray;
    // yearBenefits.clear();  // Clear existing inputs
    const years = Math.ceil(duration / 12);

    const form = <FormArray>(<unknown>this.planAddForm.controls["yearBenefits"]);
    form.clear();
    for (let i = 0; i < years; i++) {
      // yearBenefits.push(new FormControl("", Validators.required));
      form.push(this.addItemSubForm());
    }
  }

  addItemSubForm() {
    return new FormGroup({
      benefit: new FormControl("", [Validators.required]),
    });
  }



  /*--------------- scroll to error area  without use api call*/
  scrollToFirstErrorNonApiCall() {
    setTimeout(() => {
      const firstErrorElements = this.errorElements.find(el => {
        return el.nativeElement.offsetHeight > 0 && !!el.nativeElement.innerHTML.trim();
      });

      if (firstErrorElements) {
        firstErrorElements.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }


  // check the error key after boolean
  hasErrorFormCheck(key: string): boolean {
    return this.formErrors.some((err => err.key === key)); // check only boolean
  }

  //filter the form array and check
  filterErrorsByKey(key: string): { key: string, value: string }[] {
    console.log('Error Messages', this.formErrors);
    console.log('Error Message Detect', this.formErrors.filter(error => error.key === key));

    return this.formErrors.filter(error => error.key === key); // return new array
  }


  //scroll to error area  with api call
  scrollToFirstError() {
    setTimeout(() => {
      const firstErrorElement = this.errorElements.find(el => {
        return !!el.nativeElement.innerHTML.trim();
      });

      if (firstErrorElement) {
        firstErrorElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }

  addNewPlan() {
    this.isSubmitted = true;
    this.scrollToFirstErrorNonApiCall();
    console.log("form", this.planAddForm);
    if (this.planAddForm.valid) {
      this.submittedData = true;
      const yearBenefitsArray = this.planAddForm.value.yearBenefits.map((benefit: any, index: number) => ({
        year: index + 1,
        rate: benefit.benefit
      }));

      let payload = {};

      if (this.selectedPlanTemplate?.id === 3) {
        payload = {
          plan_template_id: this.planAddForm.value.planTemplate.id,
          name: this.planAddForm.value.name,
          minimum_amount: this.planAddForm.value.minimumAmount,
          duration: this.planAddForm.value.duration,
          profit_per_month: this.planAddForm.value.profitPerMonth,
          benefit_per_month: this.planAddForm.value.benefitPerYear
        };
      }

      if (this.selectedPlanTemplate?.id === 4) {
        payload = {
          plan_template_id: this.planAddForm.value.planTemplate.id,
          name: this.planAddForm.value.name,
          minimum_amount: this.planAddForm.value.minimumAmount,
          duration: this.planAddForm.value.duration,
          benefit_per_month: this.planAddForm.value.benefitPerYear
        };
      }

      if (this.selectedPlanTemplate?.id === 1 || this.selectedPlanTemplate?.id === 2) {
        payload = {
          plan_template_id: this.planAddForm.value.planTemplate.id,
          name: this.planAddForm.value.name,
          minimum_amount: this.planAddForm.value.minimumAmount,
          duration: this.planAddForm.value.duration,
          interest_rates: yearBenefitsArray,
        };
      }

      console.log("payload", payload);

      this.ngxLoader.start();
      this.planService.create(payload).subscribe({
        next: (res: any) => {
          this.isSubmitted = false;
          this.submittedData = false;
          this.planAddForm.reset();
          this.toastr.success(res.message ? res.message : "Plan added successfully", 'Success');

          this.router.navigate(['/plans']);
          this.ngxLoader.stop();
        },
        error: (error: HttpErrorResponse) => {
          this.isSubmitted = false;
          this.submittedData = false;
          this.ngxLoader.stop();

          this.formErrors = [];
          if (error.status === 422 && error.error) {
            console.log('employee add error', error.error);

            for (const [key, value] of Object.entries(error?.error)) {
              if (typeof value === 'object' && value != null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                  this.formErrors.push({ key: nestedKey, value: nestedValue as string });
                  this.submittedData = false;
                }
                this.scrollToFirstError();
              } else {
                this.formErrors.push({ key: key, value: value as string })
                this.submittedData = false;
                this.scrollToFirstError();
              }
            }
          } else {
            this.toastr.error(error.message || "Something went wrong, Please try again later.", "Error!");
          }
        },
      });
    } else {
      this.ngxLoader.stop();
    }
  }
  onClickCancel() {
    this.router.navigate(['/plans']);
  }

  getOrdinalSuffix(i: number): string {
    const j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

}
