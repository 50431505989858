<!-- <app-breadcrumbs
  title="Create New Plan"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs> -->

<div class="row">
  <div class="col-lg-12">
    <span class="d-flex">
      <i
        (click)="onClickCancel()"
        class="pi pi-arrow-left"
        style="
          font-size: 1.4rem;
          stroke-width: 0.4;
          font-size: 1.4rem;
          margin: 5px 15px 0px 1px;
          cursor: pointer;
          color: #878a99;
        "
      ></i>
      <h2 class="mb-4 text-primary">Create New Plan</h2>
    </span>
    <div class="card">
      <form
        [formGroup]="planAddForm"
        (ngSubmit)="addNewPlan()"
        enctype="multipart/form-data"
      >
        <div class="card-body">
          <div class="live-preview">
            <div class="row gy-4">
              <div class="col-xxl-12 col-md-12">
                <label for="choices-single-default" class="form-label"
                  >Plan Template <span class="required-star">*</span></label
                >
                <ng-select
                  [items]="planTemplates"
                  (change)="onPlanTemplateChange($event)"
                  placeholder="Select a Plan Template"
                  formControlName="planTemplate"
                  id="planTemplate"
                  bindLabel="name"
                  [clearable]="false"
                  [(ngModel)]="planTemplate"
                  class="my-dropdown"
                >
                  <ng-template ng-optgroup-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <small
                  #planTemplateError
                  *ngIf="
                    isSubmitted &&
                    planAddForm.get('planTemplate')?.hasError('required')
                  "
                  class="text-danger m-0"
                >
                  Plan template is required
                </small>
                <span
                  *ngIf="hasErrorFormCheck('plan_template_id')"
                  #planTemplateError
                >
                  <app-error [errors]="filterErrorsByKey('plan_template_id')">
                  </app-error>
                </span>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="basiInput" class="form-label"
                    >Name <span class="required-star">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    id="basiInput"
                    appTextInput="text"
                    placeholder="Plan Name"
                    appMaxInputLimit
                  />
                  <small
                    #planNameError
                    *ngIf="
                      isSubmitted &&
                      planAddForm.get('name')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Name is required
                  </small>
                  <span *ngIf="hasErrorFormCheck('name')" #planNameError>
                    <app-error [errors]="filterErrorsByKey('name')">
                    </app-error>
                  </span>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="basiInput" class="form-label"
                    >Minimum Amount <span class="required-star">*</span></label
                  >
                  <input
                    appDisallowMinus
                    type="number"
                    class="form-control"
                    formControlName="minimumAmount"
                    id="basiInput"
                    placeholder="Minimum Amount"
                    appMaxInputLimit
                    [maxLength]="30"
                  />
                  <small
                    #planMinimumAmountError
                    *ngIf="
                      isSubmitted &&
                      planAddForm.get('minimumAmount')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Minimum amount is required
                  </small>
                  <span
                    *ngIf="hasErrorFormCheck('minimum_amount')"
                    #planMinimumAmountError
                  >
                    <app-error [errors]="filterErrorsByKey('minimum_amount')">
                    </app-error>
                  </span>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="basiInput" class="form-label"
                    >Duration in Months
                    <span class="required-star">*</span></label
                  >
                  <input
                    removeFullsTop
                    appDisallowMinus
                    (input)="inputOnChange($event)"
                    [disabled]="isDurationDisabled"
                    appMaxNumber
                    type="number"
                    class="form-control"
                    formControlName="duration"
                    placeholder="Duration in Months"
                    (keyup)="
                      selectedPlanTemplate?.id == 1 ||
                      selectedPlanTemplate?.id == 2
                        ? onDurationChange($event)
                        : null
                    "
                    id="basiInput"
                  />
                  <small
                    #planDurationError
                    *ngIf="
                      isSubmitted &&
                      planAddForm.get('duration')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Duration is required
                  </small>
                  <span *ngIf="durationZeroValidation">
                    <small class="text-danger m-0">
                      '0' is invalid duration in month
                    </small></span
                  >
                  <span
                    *ngIf="hasErrorFormCheck('duration')"
                    #planDurationError
                  >
                    <app-error [errors]="filterErrorsByKey('duration')">
                    </app-error>
                  </span>
                </div>
              </div>
              <!--end col-->

              <div class="col-xxl-12 col-md-12" *ngIf="selectedPlanTemplate">
                <hr *ngIf="commissionRateTopicView" />

                <div
                  class="row"
                  id="profit-benefit"
                  *ngIf="selectedPlanTemplate?.id === 3"
                >
                  <div class="col-xxl-6 col-md-6">
                    <div>
                      <label for="basiInput" class="form-label"
                        >Profit Per Month (%)
                        <span class="required-star">*</span></label
                      >
                      <input
                        type="number"
                        class="form-control"
                        formControlName="profitPerMonth"
                        id="basiInput"
                        placeholder="Profit"
                        appDisallowMinus
                        appMaxInputLimit
                        [maxLength]="30"
                      />
                      <small
                        *ngIf="
                          isSubmitted &&
                          planAddForm
                            .get('profitPerMonth')
                            ?.hasError('required')
                        "
                        class="text-danger m-0"
                      >
                        Profit per month is required
                      </small>
                      <span *ngIf="hasErrorFormCheck('profit_per_month')">
                        <app-error
                          [errors]="filterErrorsByKey('profit_per_month')"
                        >
                        </app-error>
                      </span>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-xxl-6 col-md-6">
                    <div>
                      <label for="basiInput" class="form-label"
                        >Benefit Per Month (%)
                        <span class="required-star">*</span></label
                      >
                      <input
                        appDisallowMinus
                        type="number"
                        class="form-control"
                        formControlName="benefitPerYear"
                        id="basiInput"
                        placeholder="Benefit"
                        appMaxInputLimit
                        [maxLength]="30"
                      />
                      <small
                        *ngIf="
                          isSubmitted &&
                          planAddForm
                            .get('benefitPerYear')
                            ?.hasError('required')
                        "
                        class="text-danger m-0"
                      >
                        Benefit per month is required
                      </small>
                      <span *ngIf="hasErrorFormCheck('benefit_per_month')">
                        <app-error
                          [errors]="filterErrorsByKey('benefit_per_month')"
                        >
                        </app-error>
                      </span>
                    </div>
                  </div>
                  <!--end col-->
                </div>

                <div
                  class="row"
                  id="profit-benefit"
                  *ngIf="selectedPlanTemplate?.id === 4"
                >
                  <div class="col-xxl-6 col-md-6">
                    <div>
                      <label for="basiInput" class="form-label"
                        >Benefit Per Month (%)
                        <span class="required-star">*</span></label
                      >
                      <input
                        appDisallowMinus
                        type="number"
                        class="form-control"
                        formControlName="benefitPerYear"
                        id="basiInput"
                        placeholder="Benefit"
                        appMaxInputLimit
                        [maxLength]="30"
                      />
                      <small
                        *ngIf="
                          isSubmitted &&
                          planAddForm
                            .get('benefitPerYear')
                            ?.hasError('required')
                        "
                        class="text-danger m-0"
                      >
                        Benefit per Month is required
                      </small>
                      <span *ngIf="hasErrorFormCheck('benefit_per_month')">
                        <app-error
                          [errors]="filterErrorsByKey('benefit_per_month')"
                        >
                        </app-error>
                      </span>
                    </div>
                  </div>
                  <!--end col-->
                </div>

                <div
                  id="benefit-only"
                  *ngIf="
                    selectedPlanTemplate?.id == 1 ||
                    selectedPlanTemplate?.id == 2
                  "
                >
                  <div *ngIf="commissionRateTopicView">
                    <label for="basiInput" class="form-label"
                      >Commission Rates</label
                    >
                  </div>

                  <div formArrayName="yearBenefits">
                    <div
                      *ngFor="let yearControl of getControls(); let i = index"
                      class="col-xxl-12 col-md-12"
                    >
                      <div
                        class="row col-xxl-12 col-md-12 mt-2"
                        [formGroupName]="i"
                      >
                        <div class="col-md-6 align-vertically-center">
                          <label class="form-label"
                            >{{ i + 1
                            }}<span class="ordinal-suffix">{{
                              getOrdinalSuffix(i + 1).slice(-2)
                            }}</span
                            >&nbsp;Year</label
                          >
                        </div>
                        <div class="col-md-6 custom-mb-20">
                          <label
                            for="yearBenefitInput-{{ i }}"
                            class="form-label"
                            >Benefit (%)
                            <span class="required-star">*</span></label
                          >

                          <input
                            appDisallowMinus
                            type="number"
                            class="form-control benefit_txt"
                            formControlName="benefit"
                            id="yearBenefitInput-{{ i }}"
                            placeholder="Benefit"
                            appMaxInputLimit
                            [maxLength]="30"
                          />
                          <small
                            *ngIf="
                              isSubmitted &&
                              yearControl?.get('benefit')?.hasError('required')
                            "
                            class="text-danger m-0"
                          >
                            Benefit value is required
                          </small>
                          <span
                            *ngIf="
                              hasErrorFormCheck('interest_rates.' + i + '.rate')
                            "
                            #planDurationError
                          >
                            <app-error
                              [errors]="
                                filterErrorsByKey(
                                  'interest_rates.' + i + '.rate'
                                )
                              "
                            >
                            </app-error>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
        <div class="card-footer custom-tr text-end">
          <button
            type="button"
            (click)="onClickCancel()"
            class="btn btn-light waves-effect waves-light me-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            [disabled]="submittedData"
            class="btn btn-primary waves-effect waves-light"
          >
            <i *ngIf="submittedData" class="mdi mdi-spin mdi-dots-circle"></i>
            {{ submittedData == true ? "Please wait ..." : "Create Plan" }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <!--end col-->
</div>

<!--end row-->
