import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'Dashboard',
    icon: 'home',
    link: '/',
  },
  // {
  //   id: 3,
  //   label: 'Employees',
  //   icon: 'users',
  //   subItems: [
  //     {
  //       id: 1,
  //       label: 'List',
  //       link: '/employees',
  //       parentId: 3
  //     },
  //     {
  //       id: 2,
  //       label: 'Add',
  //       link: '/employees/add',
  //       parentId: 3
  //     }
  //   ]
  // },
  {
    id: 3,
    label: 'Employees',
    icon: 'users',
    permissions: ['employees-view'],
    link: '/employees',
  },
  // {
  //   id: 4,
  //   label: 'Role Permissions',
  //   icon: 'user-check',
  //   link: '/role-permission/roles',
  // },
  {
    id: 5,
    label: 'Designations',
    icon: 'pocket',
    permissions: ['designations-view'],
    link: '/designations',
  },
  {
    id: 6,
    label: 'Admin Hierarchy',
    icon: 'triangle',
    permissions: ['administrative-levels-view'],
    link: '/admin-hierarchy',
  },
  {
    id: 7,
    label: 'Plans',
    icon: 'book-open',
    permissions: ['plans-view'],
    link: '/plans',
  },
  {
    id: 8,
    label: 'Quotations',
    icon: 'file-text',
    permissions: ['quotations-view'],
    link: '/quotations',
  },
  {
    id: 9,
    label: 'Proposals',
    icon: 'save',
    permissions: ['user-packages-view'],
    link: '/proposals',
  },
  {
    id: 10,
    label: 'Benefits',
    icon: 'award',
    link: '/benefits',
    permissions: ['benefits-view-all'],
  },
  {
    id: 11,
    label: 'Due Payments',
    icon: 'credit-card',
    link: '/payments',
    permissions: ['payments-view-all'],
  },
  {
    id: 12,
    label: 'Receipts',
    icon: 'book',
    link: '/receipts',
    permissions: ['payments-view-all'],
  },
  {
    id: 13,
    label: 'Customer Audience',
    icon: 'user',
    link: '/customer-audience',
    permissions: ['customers-view-all']
  }
];
