<div class="row">
  <div class="col-lg-12">
    <h2 class="mb-4 text-primary">
      <i-feather name="book" class="text-primary icon-dual"></i-feather>
      Receipts
    </h2>
    <div class="card">
      <div class="card-body">
        <div class="my-3 custom-tr my-3">
          <div class="row">
            <div class="col-4">
              <label>Search By Receipt Number And Reference Number</label>
              <input
                [(ngModel)]="searchValue"
                type="text"
                (input)="filterTheReceipt()"
                class="form-control"
                id="txtsearch"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>

        <p-table
          [value]="filterRequest"
          styleClass="p-datatable"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <ng-template pTemplate="header">
            <tr>
              <!-- <th>Benefit ID</th> -->
              <th>Receipt Number</th>
              <th>Payment Date</th>
              <th>Reference Number</th>
              <th class="text-end">Amount (LKR)</th>
              <!--  <th>User Package Id</th> -->
              <th class="text-end">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-request>
            <tr>
              <!--  <td>{{ request.id }}</td> -->
              <td>CGLPRP - 000{{ request.receipt_number }}</td>
              <td>{{ request.payment_date }}</td>
              <td>{{ request.ref_number }}</td>
              <td class="text-end">
                {{ request.amount | number : "1.2-2" }}
              </td>
              <!--     <td>{{ receiptList.user_package_id }}</td> -->

              <td class="text-end">
                <button
                  type="button"
                  class="btn btn-primary btn-md bg-gradient waves-effect waves-light"
                  data-bs-toggle="modal"
                  data-bs-target="#viewModal"
                  data-bs-whatever="Mary"
                  (click)="openReceiptModal(request, receiptDetailModal)"
                >
                  <i
                    class="pi pi-file text-white"
                    style="font-size: 1rem; stroke-width: 0.4"
                  ></i>
                  View
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- is empty -->
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center" colspan="7">
                <img
                  *ngIf="filterRequest?.length === 0"
                  src="../../../../assets/images/cgl/other/noData.PNG"
                  alt="nodata"
                />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- benefit detail -->

<!-- <app-confirmation
    (approve)="handleApproval()"
    (cancel)="handleCancel()"
    #confirmDialog
  ></app-confirmation> -->

<!-- receipt detail -->
<ng-template #receiptDetailModal role="document" let-modal>
  <div class="modal-header pb-1"></div>

  <div class="modal-body modal-xxl pt-2 custom-modal-size">
    <div class="container-fluid">
      <div class="profile-foreground position-relative mx-n4 mt-n4">
        <div class="profile-wid-bg">
          <img
            src="../../../assets/images/cgl/other/bg_plantsAllView.jpeg"
            alt=""
            class="profile-wid-img"
          />
        </div>
      </div>
      <div class="pt-4 profile-wrapper">
        <div class="row g-4">
          <!--end col-->
          <div class="col">
            <div class="p-2">
              <div class="row m-0">
                <div class="col-10 p-0">
                  <div class="row m-0">
                    <h3 class="text-white mb-1 p-0">Receipt Details</h3>
                  </div>
                </div>
                <div class="col-2 p-0 text-end">
                  <!--  <button
                        (click)="modal.dismiss('Cross click')"
                        class="btn btn-secondary"
                        aria-hidden="true"
                      >
                        <i class="align-bottom"></i> Close
                      </button> -->
                  <i
                    class="pi pi-times"
                    style="font-size: 27px; color: azure; cursor: pointer"
                    (click)="modal.dismiss('Cross click')"
                  ></i>
                </div>
              </div>

              <!-- <p class="text-white text-opacity-75"></p> -->
              <!--  <div class="hstack text-white-50 gap-1">
                  <div class="me-2">
                    <a
                        [href]="'tel:' + viewModalData.mobile_number"
                        class="text-white text-opacity-75"
                        style="text-decoration: underline"
                      >
                        <i
                          class="text-white text-opacity-75 fs-16 align-middle"
                        ></i>
                        Harsha Bandara
                      </a> 
                  </div>
                </div> -->
            </div>
          </div>
        </div>
        <!--end row-->
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div>
            <div class="d-flex profile-wrapper">
              <!-- Nav tabs -->
              <ul
                class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                role="tablist"
              ></ul>
              <div class="flex-shrink-0">
                <!-- <a
                      (click)="navigateToEditEmployee(viewModalData.id)"
                      class="btn btn-success"
                      ><i class="ri-edit-box-line align-bottom"></i> Edit
                      Employee</a
                    > -->
              </div>
            </div>
            <!-- Tab panes -->
            <div class="tab-content pt-3 text-muted">
              <div class="tab-pane active" id="overview-tab" role="tabpanel">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col-xxl-12 col-md-12">
                        <div class="card h-100 shadow extraStyle">
                          <div class="card-body extraPadding">
                            <h5 class="card-title">Receipt Details</h5>
                            <div class="table-responsive">
                              <table class="table table-borderless mb-0">
                                <tbody class="employee-tbl">
                                  <!-- <tr>
                                    <th
                                      class="ps-0 text-muted th_width"
                                      scope="row"
                                    >
                                      Receipt Number :
                                    </th>
                                    <td class="text-muted">
                                      {{ isReceptionist.receipt_number }}
                                    </td>
                                  </tr> -->
                                  <tr>
                                    <th
                                      class="ps-0 text-muted th_width rowPadding"
                                      scope="row"
                                    >
                                      Payment Date
                                    </th>
                                    <td class="text-muted rowPadding">
                                      <b>:</b>
                                      {{ isReceptionist.payment_date ?? "-" }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 text-muted th_width rowPadding"
                                      scope="row"
                                    >
                                      Reference Number
                                    </th>
                                    <td class="text-muted rowPadding">
                                      <b>:</b>
                                      {{ isReceptionist.ref_number ?? "-" }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 text-muted th_width rowPadding"
                                      scope="row"
                                    >
                                      Amount (LKR)
                                    </th>
                                    <td class="text-muted rowPadding">
                                      <b>:</b>
                                      {{
                                        isReceptionist.amount | number : "1.2-2"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <!--  -->
                        </div>
                      </div>

                      <div class="col-xxl-12 col-md-12 mt-2">
                        <div class="h-100 extraStyle">
                          <div class="">
                            <h5 class="card-title mb-2 mt-5 pckName">
                              Package Payments History
                            </h5>
                            @for(item of isReceiptPackages;track $index ){
                            <div
                              class="mt-4 card profile-project-card shadow-none profile-project-success mb-0"
                            >
                              <div class="card_2" style="padding: 10px">
                                <h6>Receipt Details</h6>
                                <div class="d-flex">
                                  <div
                                    class="flex-grow-1 text-muted overflow-hidden"
                                  ></div>
                                </div>
                                <div class="d-flex mt-2">
                                  <div class="flex-grow-1">
                                    <div
                                      class="d-flex align-items-center gap-2"
                                    >
                                      <table
                                        class="table table-borderless mb-0"
                                      >
                                        <tbody class="employee-tbl">
                                          <tr>
                                            <th
                                              class="ps-0 min_widthForCard historyFont"
                                              scope="row"
                                            >
                                              Payment Date
                                            </th>
                                            <td class="text-muted historyData">
                                              <b>:</b>
                                              {{ item.payment_date ?? "-" }}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th
                                              class="ps-0 min_widthForCard historyFont"
                                              scope="row"
                                            >
                                              Reference Number
                                            </th>
                                            <td class="text-muted historyData">
                                              <b>:</b>
                                              {{ item.receipt_number ?? "-" }}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th
                                              class="ps-0 min_widthForCard historyFont"
                                              scope="row"
                                            >
                                              Amount (LKR)
                                            </th>
                                            <td class="text-muted historyData">
                                              <b>:</b>
                                              {{
                                                (item.amount
                                                  | number : "1.2-2") ?? "-"
                                              }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- end card body -->
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end row-->
              </div>
            </div>
            <!--end tab-content-->
          </div>
        </div>

        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
</ng-template>
