import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMaxNumber]'
})
export class MaxNumberDirective {

  private readonly maxValue: number = 240;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = parseInt(input.value, 10);

    if (value > this.maxValue) {
      input.value = this.maxValue.toString();
      event.preventDefault();
    }
  }
}
