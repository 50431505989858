<!-- start client section -->
<div class="pt-5 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">

                <div class="text-center mt-5">
                    <h5 class="fs-20">Trusted <span class="text-primary text-decoration-underline">by</span> the world's
                        best</h5>
                    <!-- Swiper -->
                    <div class="swiper trusted-client-slider mt-sm-5 mt-4 mb-sm-5 mb-4" dir="ltr">
                        <ngx-slick-carousel class="carousel" [config]="Responsive">
                            @for (data of ClientLogo; track $index) {
                            <div class="swiper-slide" ngxSlickItem>
                                <div class="client-images">
                                    <img src="{{data.image}}" alt="client-img" class="mx-auto img-fluid d-block">
                                </div>
                            </div>}
                        </ngx-slick-carousel>
                    </div>
                </div>

            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end client section -->