import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-hierarchy-breadcrumb',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hierarchy-breadcrumb.component.html',
  styleUrl: './hierarchy-breadcrumb.component.scss'
})
export class HierarchyBreadcrumbComponent {
  @Input() breadcrumbItems:any;
}
