import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EmployeeService {

    private http = inject(HttpClient);
    private API_URL_PREFIX: string = environment.apiURL + '/admin/employees';
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    add(payload: any) {
        return this.http.post(this.API_URL_PREFIX, payload, this.httpOptions);
    }

    getById(id: string) {
        return this.http.get(this.API_URL_PREFIX + '/' + id, this.httpOptions);
    }

    getAll(queryParams?: any) {
        let params = new HttpParams();
        if (queryParams) {
            for (const key in queryParams) {
                if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
                    params = params.set(key, queryParams[key]);
                }
            }
        }
        return this.http.get(this.API_URL_PREFIX, {
            headers: this.httpOptions.headers,
            params: params
        });
    }

    findById(id: string) {
        return this.http.get(this.API_URL_PREFIX + '/' + id, this.httpOptions);
    }

    update(id: string, payload: object) {
        return this.http.put(this.API_URL_PREFIX + '/' + id, payload, this.httpOptions);
    }

    delete(id: string) {
        return this.http.delete(this.API_URL_PREFIX + '/' + id, this.httpOptions);
    }

}
