<p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
      <div
        style="
          background-color: white;
          border-radius: 10px;
          display: flex;
          padding: 2rem;
        "
        class="flex flex-column align-items-center surface-overlay border-round"
      >
        <div
          style="width: 5rem; height: 5rem; border-radius: 60%; display: flex;background-color: #ee5253 !important;"
          class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem"
        >
          <i
            style="color: white; width: 30px; height: 30px; font-size: xx-large"
            class="pi pi-question"
          ></i>
        </div>
        <span
          style="font-weight: bold; font-weight: bold; font-size: 1.4rem"
          class="text-2xl block mb-2 mt-3"
        >
          {{ message.header }}
        </span>
        <p class="mb-0">
          {{ message.message }}
        </p>
        <div
          style="display: flex; width: 100%; justify-content: center"
          class="mt-4"
        >
          <button
            style="
              width: 8rem;
              height: 2.3rem;
              border-radius: 7px;
              border: 1px solid #ee5253;
              margin: 0 5px 0px 5px;
            "
            pButton
            label="Delete"
            (click)="onApprove()"
            class="deleteCustomButtonPlantation p-2"
          ></button>
          <button
            style="
              width: 8rem;
              height: 2.3rem;
              border-radius: 7px;
              margin: 0 5px 0px 5px;
            "
            pButton
            label="Cancel"
            (click)="onCancel()"
            class="p-button-outlined p-2"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>