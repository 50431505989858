import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherModule } from "angular-feather";
import { TableModule } from 'primeng/table';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from "src/app/shared/shared.module";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BenefitService } from 'src/app/services/benefit.service';
import { FormsModule } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { ToastrService } from 'ngx-toastr';
import { TagModule } from 'primeng/tag';




@Component({
  selector: 'app-benefit',
  standalone: true,
  imports: [CommonModule, FeatherModule, TableModule, NgSelectModule, SharedModule, FormsModule, SharedModule, TagModule],
  templateUrl: './benefit.component.html',
  styleUrl: './benefit.component.scss'
})

export class BenefitComponent implements OnInit {
  @ViewChild('confirmDialog') confirmDialog: any;
  benefitList: any;
  selectedBenefit: any;
  paymentReference: any;
  referenceError: boolean = false;
  paymentStatuses = [{ name: 'All', value: null }, { name: 'Paid', value: 'PAID' }, { name: 'Unpaid', value: 'UNPAID' }];
  selectedPaymentStatus = this.paymentStatuses[0];

  isDisplay: any;
  isDisplayPaymentReference: any;
  private benefitService = inject(BenefitService);
  private modalService = inject(NgbModal);
  private ngxLoader = inject(NgxUiLoaderService);
  private toastr = inject(ToastrService);

  ngOnInit(): void {
    this.getAllBenefits();
  }

  EmptyIconHandle: boolean = false;
  getAllBenefits() {
    this.ngxLoader.start();
    this.benefitService.getAllBenefits().subscribe((resp: any) => {
      this.benefitList = resp.data;
      this.ngxLoader.stop();
      // console.log('is empty',);s
      // this.benefitList.length === 0 ? this.EmptyIconHandle = true : this.EmptyIconHandle = false;
    });


  }

  onPaymentStatusChange(event: any) {
    console.log("thiss", event.value);
    let status = null;
    if (event.value) {
      status = { payment_status: event.value };
    }

    this.ngxLoader.start();
    this.benefitService.getAllBenefits(status).subscribe((resp: any) => {
      this.benefitList = resp.data;
      this.ngxLoader.stop();
    });
  }

  openBenefitModel(benefit: any, content: any) {

    this.selectedBenefit = benefit;
    this.modalService.open(content);

    this.isDisplay = benefit.paid_at;
    this.isDisplayPaymentReference = benefit.payment_reference;
    //console.log('image Display',this.isImageDisplay);
  }


  getSeverity(paid: any): any {
    // console.log('paid', paid);
    if (paid === undefined) {
      return 'danger';
    } else {
      return 'success';
    }
  }

  onClickPaid() {
    if (this.paymentReference) {
      this.referenceError = false;
      this.confirmDialog.show('Confirmation', 'Are you sure you want to mark this as paid ?');
    }
    else {
      this.referenceError = true;
    }

  }

  handleApproval() {

    let payload = {
      "benefit_id": this.selectedBenefit.id,
      "reference": this.paymentReference
    }
    this.ngxLoader.start();
    this.benefitService.markAsPaid(payload).subscribe({
      next: (response: ApiResponse) => {
        this.toastr.success('Marked as paid successfully', 'Success');
        this.getAllBenefits();
        this.ngxLoader.stop();
        this.modalService.dismissAll("close");
      },
      error: (e) => {
        // this.getAllFiles(this.parent_id, this.category_name);
        this.ngxLoader.stop();
        this.modalService.dismissAll("close");
      }, complete: () => {
        this.paymentReference = null;
      }
    });
    this.confirmDialog.close();
  }

  handleCancel() {
    this.confirmDialog.close();
  }
}
