import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TagModule } from 'primeng/tag';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanService } from 'src/app/services/plans.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { FeatherModule } from 'angular-feather';
import { MessageService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-all-plans',
  standalone: true,
  imports: [CommonModule, SharedModule, ConfirmDialogModule, ButtonModule, ReactiveFormsModule, TagModule, ProgressBarModule, TableModule, RouterModule, FormsModule, FeatherModule, ConfirmPopupModule],
  providers: [ConfirmationService],
  templateUrl: './all-plans.component.html',
  styleUrl: './all-plans.component.scss'
})
export class AllPlansComponent {

  breadCrumbItems!: Array<{}>;
  filteredRequests: any;
  searchValue: string = "";
  plansList: any;
  planId: any;
  viewedPlan: any;

  private modalService = inject(NgbModal);
  private planService = inject(PlanService);
  private toastr = inject(ToastrService);
  private confirmationService = inject(ConfirmationService);
  private ngxLoader = inject(NgxUiLoaderService);

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Plans", active: true },
    ];

    this.getAllPlans();
  }

  getAllPlans() {
    this.ngxLoader.start();
    this.planService.findAll().subscribe((resp: any) => {
      console.log(resp);
      this.plansList = resp.data;
      this.filteredRequests = resp.data;
      this.ngxLoader.stop();
    });
  }

  openPlanViewModel(content: any, id: any) {
    this.viewedPlan = this.plansList.find((item: any) => item.id === id);
   // console.log('Content', this.viewedPlan);
    this.modalService.open(content);

  }

  filterRequests() {
    this.filteredRequests = this.plansList.filter((request: any) => {
     // console.log('request', request);
      return (request.name.toLowerCase().includes(this.searchValue.toLowerCase())
        || request.code.toLowerCase().includes(this.searchValue.toLowerCase()))
    }
    );
  }
  deletePlan(event: Event, id: any, name: any) {
    this.planId = id;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: 'Are you sure?',
      message: `${name}`,
      icon: 'pi pi-question',
      accept: () => {
        this.accept();
      },
      reject: () => {
        this.reject();
      }
    });
  }



  accept(): void {
    this.ngxLoader.start();
    this.planService.delete(this.planId).subscribe({
      next: (response: ApiResponse) => {
        this.getAllPlans();
        this.toastr.success('Plan Deleted', 'Success');
        this.ngxLoader.stop();
      },
      error: (e) => {
        // this.getAllFiles(this.parent_id, this.category_name);
        // this.toastr.error('Something Went wrong', 'error');
        this.ngxLoader.stop();
      },
    });
    this.confirmationService.close();
  }

  reject(): void {
    this.confirmationService.close();
  }

}
