<div class="swith_main">
  <!--  <h2> Select Branch</h2> -->
  <div class="p-4 swich-Child row">
    <!-- ----------------------------------1 -->

    @for (item of switchBranches; track $index) {
    <div class="col-xl-3 col-md-6" [ngClass]="{ 'ribbon-box right': true }">
      <div
        class="ribbon-two nic_Frount ribbon-two-success ribbon_Hover"
        *ngIf="item?.is_current_branch"
      >
        <span>Active</span>
      </div>
      <!-- card -->
      <div class="card card-animate crd_bx" (click)="onClickBranch(item.id)">
        <div class="card-body">
          <div class="d-flex align-items-center extraCardBody">
            <div class="">
              <!-- flex-grow-1 -->
              <h4 class="fw-medium mb-0 text-center">
                {{ capitalizeFirstLetter(item.branch_name) }}
              </h4>
            </div>
            <div class="img_user flex-shrink-0">
              <img
                [width]="40"
                src="../../../assets/images/cgl/logo/people.png"
                alt="user"
              />
            </div>
          </div>
          <!-- <div class="d-flex align-items-end justify-content-between mt-4">
            
            <div>
                                        <h4 class="fs-22 fw-semibold ff-secondary mb-4" >
                                            {{data.prefix}}<span [countUp]="data.counter" class="counter-value" [options]="option"></span>{{data.suffix}}</h4>
                                        <a href="javascript:void(0);" class="text-decoration-underline" [ngClass]=" { 'text-white-50': data.captionClass}">{{data.caption}}</a>
                                    </div>
            <div class="avatar-sm flex-shrink-0">
              <span class="avatar-title {{data.iconClass}} rounded fs-3">
                                            <i class="{{data.icon}} text-{{data.iconClass}}"></i>
                                        </span>
            </div>
          </div> -->
        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    }
    <!------------------- 2 -->

    <!-- ---------------------3 -->
  </div>
  <div>
    <h5 style="font-weight: 400">
      Please select branch Or
      <span class="link_DashBoard" (click)="goBackToDashBoard()"
        >Go Back to Dashboard</span
      >
    </h5>
  </div>
</div>
