import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MediaService {

    private http = inject(HttpClient);
    private API_URL_PREFIX: string = environment.apiURL + '/admin/media';

    // uploadFile(file: any) {
    //     return this.http.post(this.API_URL_PREFIX + '/upload', file, {
    //         headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    //     });
    //     // return this.http.post(this.API_URL_PREFIX + '/upload', file);
    // }


    uploadFile(file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData?.append('file', file, file?.name);
        return this.http.post(this.API_URL_PREFIX + '/upload', formData);
    }

    // for userProfile
    updateProfileImage(profileImage: any): Observable<any> {
        console.log('profile Image :', profileImage);
        return this.http.post(`${this.API_URL_PREFIX}/upload`, profileImage);
    }


    getUploadUrl(): string {
        // Synchronous URL generation

        return this.API_URL_PREFIX + '/upload'; // Replace with your logic
    }

}
