import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[validateEmail]'
})
export class ValidateEmailExtra {

  constructor() { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === '+' ||
      event.key === '-' ||
      event.key === '=' ||
      event.key === '$' ||
      event.key === '*' ||
      event.key === '%') {
      event.preventDefault();
    }
  }

}
