import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[appNicToDob]'
})
export class NicToDobDirective {

  @Input('appNicToDob') dobControl!: FormControl;

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(nic: string): void {
    this.calculateDOBFromNIC(nic);
  }

  calculateDOBFromNIC(nic: string): void {
    if (!nic || !this.dobControl) return;

    let birthYear = '';
    let birthDOY = 0;

    if (nic.length === 10 && (nic.endsWith('V') || nic.endsWith('X'))) {

      birthYear = '19' + nic.substring(0, 2);
      birthDOY = +nic.substring(2, 5);
    } else if (nic.length === 12) {

      birthYear = nic.substring(0, 4);
      birthDOY = +nic.substring(4, 7);
    }

    if (birthDOY > 500) {
      birthDOY -= 500; // Adjust for females (500+ indicates female in NIC)
    }

    const birthDate = new Date(+birthYear, 0, birthDOY);
    this.dobControl.setValue(birthDate.toISOString().split('T')[0]);
  }
}
