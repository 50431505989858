import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MediaService } from 'src/app/services/media.service';
import { lastValueFrom } from 'rxjs';
import { UserProfileService } from 'src/app/services/profile.service';
import { ToastrService } from "ngx-toastr";
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorComponent } from "../../../shared/components/errorHandle/error/error.component";
@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule, FormsModule, ErrorComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})

export class ProfileComponent implements OnInit {


  private formBuilder = inject(FormBuilder);
  private toastr = inject(ToastrService);
  private ngxLoader = inject(NgxUiLoaderService);
  private mediaService = inject(MediaService);
  private userProfileService = inject(UserProfileService);

  profileDetail: any;
  fieldTextType: boolean = false;
  fieldTextTypeNew: boolean = false;
  fieldTextTypeConfirm: boolean = false;
  imageURL: string | undefined;
  selectedFiles: any;
  profileUpdateForm!: FormGroup;
  passwordResetForm!: FormGroup;
  isSubmitted: boolean = false;
  isSubmittedData: boolean = false;
  loginUserId: string | number | undefined | any;
  //selectedFiles: SelectedFile[] = [];
  setImagePath: any
  formErrors: { key: string, value: string }[] = [];
  loginUserFirstName: string = '';
  loginUserMiddleName: string = '';
  setImageForTopBar: string = '';
  userRole: string = '';

  newPassword: string = '';
  passwordError: string = '';
  passwordSuccess: string = '';
  isSuccessPassword: boolean = false;

  ngOnInit(): void {
    this.initProfileData();
    this.getProfileDetail();
    const loginUser = JSON.parse(localStorage.getItem('currentUser')!)

    this.loginUserFirstName = loginUser.user.first_name;
    this.loginUserMiddleName = loginUser.user.middle_name;
    this.userRole = loginUser.authorization.role_name;
  }


  initProfileData() {
    this.profileUpdateForm = this.formBuilder.group({
      userName: ["", Validators.required]
    });

    this.passwordResetForm = this.formBuilder.group({
      currentPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      conformPassword: ["", Validators.required]
    })
  }

  getProfileDetail() {
    this.ngxLoader.start()
    this.userProfileService.getProfileDetail().subscribe({
      next: ((response: ApiResponse) => {
        this.profileDetail = response?.data;
        this.patchProfileValue()
        this.ngxLoader.stop();
      }), error: ((error: HttpErrorResponse) => {
        this.ngxLoader.stop()
      })
    })
  }

  patchProfileValue() {
    this.profileUpdateForm.patchValue({
      userName: this.profileDetail.username
    })
    this.setImagePath = this.profileDetail?.media_array?.paths?.medium;
    localStorage.setItem('userImage', this.setImagePath);
  }

  unSupportedError: string = '';
  selectedFilesInclude: boolean = false;

  fileChange(event: any) {
    let maxSizeInMb = 3;
    let maxSizeInBytes = maxSizeInMb * 1024 * 1024;
    let fileList: any = (event.target as HTMLInputElement);  // grab the html related content

    let file: File = fileList.files[0];
    document.getElementById('')

    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowedTypes.includes(file?.type)) {
      this.toastr.error("Invalid file type, only (.png, .jpeg, .jpg ) are allowed", "Error");
    } else if (file?.size > maxSizeInBytes) {
      this.toastr.error("File size exceeds the maximum limit of 3 MB", "Error");
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL = reader.result as string;
        (document.getElementById('product-img') as HTMLImageElement).src = this.imageURL;
      }
      reader.readAsDataURL(file)
      this.selectedFiles = file;

      //  file ? this.selectedFilesInclude = true : this.selectedFilesInclude = false
      if (file) {
        this.selectedFilesInclude = true

      } else {
        this.selectedFilesInclude = false
      }
    }
  }


  /* update user Name and image */
  onUpdateUserProfile() {
    this.isSubmitted = true;
    if (this.selectedFilesInclude === true) {
      if (this.profileUpdateForm.valid) {
        const formData = new FormData();
        formData.append('file', this.selectedFiles);
        this.ngxLoader.start()
        const fileUploadRes$ = this.mediaService.updateProfileImage(formData)
        const isFileUpload = lastValueFrom(fileUploadRes$);

        isFileUpload.then((response: any) => {

          if (response && response?.data) {
            // const images = response.data.map((item: any) => ({ id: item?.id }));
            const images = response.data?.id;

            const payload = {
              media_id: images,
              username: this.profileUpdateForm.value.userName,
            }
            const profileUpdateRes$ = this.userProfileService.updateProfile(payload);
            const profileUpdateRes = lastValueFrom(profileUpdateRes$);

            profileUpdateRes.then(
              (response: ApiResponse) => {
                //console.log('New Response :', response);
                this.isSubmitted = false;
                this.profileUpdateForm.reset();
                this.ngxLoader.stop();
                this.getProfileDetail();
                this.toastr.success("Profile updated successfully", "Success");
              },
              (error) => {
                this.isSubmitted = false;
                this.ngxLoader.stop();
                this.toastr.error("Error updating profile", "Error");
              }
            );
          }
        })
      }
    } else {
      this.isSubmitted = true;
      if (this.profileUpdateForm.valid) {
        const payload = {
          username: this.profileUpdateForm.value.userName
        }
        this.ngxLoader.start();
        this.userProfileService.updateProfile(payload).subscribe({
          next: (res: ApiResponse) => {
            this.isSubmitted = false;
            this.getProfileDetail();

            this.toastr.success("Profile updated successfully", "Success");
          }, error: (err: HttpErrorResponse) => {
            this.isSubmitted = false;
          }, complete: () => {
            this.isSubmitted = false;
            this.ngxLoader.stop();
          },
        })
      }
    }
  }

  passwordValidate(event: any) {
    const password = this.newPassword;
    console.log(this.newPassword);


    const minLength = /.{8,}/;
    const hasNumber = /[0-9]/;
    const hasUppercase = /[A-Z]/;
    const hasLowercase = /[a-z]/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;


    if (!minLength.test(password)) {
      this.passwordError = 'Password must be at least 8 characters long';
      this.isSuccessPassword = false;
    } else if (!hasNumber.test(password)) {
      this.passwordError = 'Password must include at least one number';
      this.isSuccessPassword = false;
    } else if (!hasUppercase.test(password)) {
      this.passwordError = 'Password must include at least one capital letter';
      this.isSuccessPassword = false;
    } else if (!hasLowercase.test(password)) {
      this.passwordError = 'Password must include at least one simple letter';
      this.isSuccessPassword = false;
    } else if (!hasSpecialChar.test(password)) {
      this.passwordError = 'Password must include at least one special character';
      this.isSuccessPassword = false;
    } else {
      this.passwordError = '';
      this.passwordSuccess = 'Password is strong';
      this.isSuccessPassword = true
    }
  }

  // check the error key after boolean
  hasErrorFormCheck(key: string): boolean {
    return this.formErrors.some((err => err.key === key)); // check only boolean
  }

  //filter the form array and check
  filterErrorsByKey(key: string): { key: string, value: string }[] {
    return this.formErrors.filter(error => error.key === key); // return new array
  }

  resetPasswordOnAction() {
    this.isSubmittedData = true
    this.formErrors = [];
    this.passwordSuccess = '';
    if (this.passwordResetForm.valid && this.isSuccessPassword) {
      const payload = {
        current_password: this.passwordResetForm.value.currentPassword,
        new_password: this.passwordResetForm.value.newPassword,
        new_password_confirmation: this.passwordResetForm.value.conformPassword
      }
      this.ngxLoader.start();
      this.userProfileService.passwordReset(payload).subscribe({
        next: (res: ApiResponse) => {
          this.isSubmittedData = false;
          this.passwordResetForm.reset();
          this.ngxLoader.stop();
          this.toastr.success("Password change successfully", "Success");
        }, error: (error: HttpErrorResponse) => {
          this.formErrors = [];
          this.isSubmittedData = false;
          if (error.status === 422 && error.error) {
            console.log('Profile error', error.error);

            for (const [key, value] of Object.entries(error?.error)) {
              if (typeof value === 'object' && value != null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                  this.formErrors.push({ key: nestedKey, value: nestedValue as string });
                  //this.submittedData = false;
                  this.ngxLoader.stop();
                }
              } else {
                this.formErrors.push({ key: key, value: value as string })
                this.ngxLoader.stop();
              }
            }
          } else {
            this.toastr.error(error.message || "Something went wrong, Please try again later.", "Error!");
            this.ngxLoader.stop();
          }

        }, complete: () => {
          this.ngxLoader.stop();
        },
      })
    }
  }

  /* password Eye click on action */
  eyeOnActionCurrent() {
    this.fieldTextType = !this.fieldTextType;
  }
  eyeOnActionNew() {
    this.fieldTextTypeNew = !this.fieldTextTypeNew;
  }
  eyeOnActionConfirm() {
    this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
  }
}
