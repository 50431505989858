import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../core/models/api-response.model";


@Injectable({ providedIn: 'root' })
export class CustomerService {

    private http = inject(HttpClient);
    private API_URL_PREFIX: string = environment.apiURL + "/admin"

    private httpOption = {
        headers: new HttpHeaders({ 'Content-type': 'application/json' }),
    }

    getallCustomer(status?: any) {
        let endPoint = this.API_URL_PREFIX;
        return this.http.get<ApiResponse>(endPoint + '/customers', this.httpOption);
    }
    updateCustomer(id?: number, payload?: Object) {
        return this.http.patch<ApiResponse>(this.API_URL_PREFIX + '/customers/' + id, payload)
    }

    findById(id?: number) {
        return this.http.get<ApiResponse>(this.API_URL_PREFIX + '/customers/' + id);
    }
}