<!-- <app-breadcrumbs title="" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs> -->

<div class="row">
  <div class="col-12">
    <h2 class="mb-4 text-primary">
      <i-feather name="file-text" class="text-primary icon-dual"></i-feather>
      Quotations
    </h2>
    <div class="card">
      <div class="card-header custom-tr my-3">
        <div class="row">
          <div class="col-6">
            <label for="txtsearch">Search By Customer Identity </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="searchValue"
              (input)="filterRequests()"
              id="txtsearch"
              placeholder="Search..."
            />
          </div>
          <div class="col-6 text-end">
            <button
              type="button"
              class="btn btn-primary waves-effect waves-light px-5"
              [routerLink]="['/quotations/add']"
            >
              <i-feather
                name="plus-circle"
                class="text-white icon-dual"
              ></i-feather>
              Add New Quotation
            </button>
          </div>
        </div>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <p-table
          [value]="filteredRequests"
          styleClass="p-datatable"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 30%">Customer Identity</th>
              <th style="width: 30%">Created At</th>
              <th style="">Quotations Count</th>
              <!--   <th style="width: 20%">Quotation Summary</th> -->
              <th class="text-end">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-request>
            <tr>
              <td>
                {{ request.first_name | capitalize }}
                {{ request.middle_name | capitalize }}
                {{ request.last_name | capitalize }}
              </td>
              <!-- <td>{{ request.created_at | date : "medium" }}</td> -->
              <td>{{ request.created_at | date : "MMM d, y, hh:mm:ss a" }}</td>

              <td>
                <!-- <small> {{ request.quotations.length }} </small> -->
                <span
                  class="quotationCount"
                  style="cursor: pointer"
                  (mouseover)="openOverLayModal($event, op, request.quotations)"
                >
                  {{
                    request.quotations.length == 1
                      ? request.quotations.length + " Quotation"
                      : request.quotations.length + " Quotations"
                  }}
                </span>
                <!--     <small>{{ getQuotationNumbers(request.quotations) }}</small> -->
              </td>
              <!--   <td> {{ request.plan_name }}</td> -->
              <td class="text-end">
                <button
                  type="button"
                  class="btn btn-primary btn-md bg-gradient waves-effect waves-light"
                  data-bs-toggle="modal"
                  data-bs-target="#quotationContentModal"
                  data-bs-whatever="Mary"
                  (click)="
                    openQuotationViewModel(quotationViewModal, request.id)
                  "
                >
                  <i
                    class="pi pi-file text-white"
                    style="font-size: 1rem; stroke-width: 0.4"
                  ></i>
                  View All
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-warning btn-sm bg-gradient waves-effect waves-light custom-mr-5"
                  [routerLink]="['/plans/edit', request.id]"
                >
                  Edit
                </button> -->
                <!-- <button
                type="button"
                pButton 
                (click)="deletePlan($event,request.id)"
                class="btn btn-danger btn-sm bg-gradient waves-effect waves-light custom-mr-5"
              >
                Delete
              </button> -->
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center" colspan="7">
                <img
                  *ngIf="filteredRequests?.length === 0"
                  src="../../../../assets/images/cgl/other/noData.PNG"
                  alt="nodata"
                />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<ng-template #quotationViewModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="quotationContentModal">Quotation View</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>

  <div class="modal-body">
    <div class="card no-border-card">
      <div class="card-header text-end">
        <button
          type="button"
          class="btn btn-primary btn-md bg-gradient waves-effect waves-light me-2"
          [disabled]="submittedData"
          (click)="onClickDownload()"
        >
          {{ submittedData == true ? "Please Wait..." : "Download" }}
        </button>
      </div>
      <div class="card-body">
        <ngx-extended-pdf-viewer
          *ngIf="!isLoading"
          [base64Src]="selectedFileB64"
          [ignoreKeyboard]="true"
          [showToolbar]="false"
          [contextMenuAllowed]="false"
          [enablePrint]="false"
        ></ngx-extended-pdf-viewer>
      </div>
    </div>

    <!-- <div class="row col-md-12">
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
        >Name:</label
      >
      </div>
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
        >{{viewedQuotation.name}}</label
      >
      </div>
    </div>
    <div class="row col-md-12">
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
        >Plan Template:</label
      >
      </div>
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
        >{{viewedQuotation.plan_template.name}}</label
      >
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
        >Total Investment</label
      >
      </div>
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
        >{{viewedQuotation.minimum_amount}}</label
      >
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
        >Duration in Months</label
      >
      </div>
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
        >{{viewedQuotation.duration}}</label
      >
      </div>
    </div> -->
  </div>
</ng-template>

<!-- overLay Panel in Quotation number view -->
<!-- <div class="card flex justify-content-center"> -->
<!-- <p-button
    (onClick)="op.toggle($event)"
    icon="pi pi-share-alt"
    label="Share"
  /> -->
<p-overlayPanel #op>
  <div class="flex flex-column gap-3 w-25rem">
    <div class="flex flex-column gap-3 w-25rem">
      <div>
        <div *ngFor="let quotationNumber of quotationNumbers">
          <small class="text-900 block mb-2 mt-1">{{ quotationNumber }}</small>
        </div>
      </div>
    </div>

    <div></div>
  </div>
</p-overlayPanel>
<!-- </div> -->
