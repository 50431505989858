import { Injectable, inject } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, from, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from "../services/auth.service";
import { TokenStorageService } from "../services/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class ErrorInterceptor implements HttpInterceptor {
  public router = inject(Router);
  private toastr = inject(ToastrService);
  private authService = inject(AuthenticationService);
  private tokenStorageService = inject(TokenStorageService);
  private ngxLoader = inject(NgxUiLoaderService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {

        console.log('the error', err);

        const error =
          err ||
          err.error ||
          err.error?.message?.message?.errors?.name ||
          err.error?.error.error_message ||
          err.error?.message.message ||
          err.error?.message ||
          err.error?.error?.id ||
          err.message.message ||
          err.statusText;

        const errorStatus = err.status;

        if (
          errorStatus == 401 ||
          err.error.error.error_message == 'Unauthenticated.'
        ) {

          this.authService.logout();
          this.toastr.error(err.error.error.error_message, "Error!")
          this.router.navigateByUrl('/auth/login');
          this.ngxLoader.stop();

        } else if (errorStatus == 403) {

          this.router.navigateByUrl('/');
          this.toastr.error(err.error?.error.error_message, "Error!");
          this.ngxLoader.stop();
        }

        // const refreshToken = this.tokenStorageService.getRefreshToken();
        if (500 <= errorStatus && errorStatus <= 599) {
          if (err.error.error.error_message) {
            this.toastr.error(err.error.error.error_message, "Error!");
          } else if (err.error_message) {
            this.toastr.error(err.error_message, "Error!");
          }
          this.ngxLoader.stop();
        } else if (400 <= errorStatus && errorStatus <= 499) {
          if (errorStatus === 422) {
            if (err.error?.error?.error_message) {
              this.toastr.error(err.error?.error.error_message, "Error!"); // when delete handle 
            } else if (err.error.error) {
              //this.toastr.error(err?.error?.error?.id, "Error!"); // when Invalid id in switch branch
            }
          } else if (errorStatus == 403) {

          } else {
            this.toastr.error(error, "Error!");
          }
        } else if (300 <= errorStatus && errorStatus <= 399) {
          this.toastr.error(error, "Error!");

        } else if (200 <= errorStatus && errorStatus <= 299) {
          this.toastr.success(error, "Success!");

        } else {
          // this.toastr.error(error, "Error!");
        }

        // if (500 <= err.status && err.status <= 599) {
        //   this.toastr.error(error, "Error!");

        // } else if (300 <= err.status && err.status <= 402) {
        //   this.toastr.error(error, "Error!");

        // } else if (404 <= err.status && err.status <= 421) {
        //   this.toastr.error(error, "Error!");

        // } else if (423 <= err.status && err.status <= 499) {
        //   this.toastr.error(error, "Error!");

        // } else if (200 <= err.status && err.status <= 299) {
        //   this.toastr.success(error, "Success!");

        // } else if (err.status === 422) {
        //   console.log(err.status);
        //   console.log(err);

        //   // if (err.error?.error?.error_message) {
        //   //   this.toastr.error(err.error?.error.error_message, "Error!");
        //   // }

        // } else if (err.status == 403) {

        // } else {
        //   this.toastr.error(error, "Error!");
        // }

        return throwError(() => error);
      })
    );
  }

  redirectToUnauthorized() {
    this.router.navigate(["/unauthorize"]);
  }

}
