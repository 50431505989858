import { ChangeDetectorRef, Component, ElementRef, inject, QueryList, ViewChildren } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { ConfirmationService, TreeNode } from "primeng/api";
import { TreeModule } from "primeng/tree";
import { BadgeModule } from 'primeng/badge';
import { AdminHierarchyService } from "src/app/services/admin-hierarchy.service";
import { TableModule } from "primeng/table";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbModalRef, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { AdminLevelService } from "src/app/services/admin-level.service";
import { FeatherModule } from "angular-feather";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ErrorComponent } from "src/app/shared/components/errorHandle/error/error.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ApiResponse } from "src/app/core/models/api-response.model";

@Component({
  selector: "app-hierarchy-list",
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TableModule,
    RouterModule,
    ButtonModule,
    ReactiveFormsModule,
    NgSelectModule,
    FeatherModule,
    BadgeModule,
    ErrorComponent,
    ConfirmDialogModule
  ],
  templateUrl: "./hierarchy-list.component.html",
  styleUrl: "./hierarchy-list.component.scss",
  providers: [ConfirmationService]
})
export class HierarchyListComponent {
  breadCrumbItems!: Array<{}>;
  adminHierarchyItems: any;
  adminLevels: any;
  adminLevel: any;
  parent: any;
  filteredRequests: any;
  hierarchyAddForm!: FormGroup;
  isSubmitted: boolean = false;
  filteredHierarchyItems: any;
  submittedData: boolean = false;
  parentDisable: boolean = false;
  parentVisible: boolean = true;
  hierarchyUpdateForm!: FormGroup


  formErrors: { key: string, value: string }[] = [];

  hierarchyId: any

  private adminHierarchyService = inject(AdminHierarchyService);
  private adminLevelService = inject(AdminLevelService);
  private toastr = inject(ToastrService);
  private modalService = inject(NgbModal);
  private ngxLoader = inject(NgxUiLoaderService);
  modalRef: NgbModalRef | undefined;
  private confirmationService = inject(ConfirmationService);


  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Admin Hierarchy", active: true },
    ];

    this.getAdminHierarchy();
    this.getAdminLevels();
    this.hierarchyAddForm = new FormGroup({
      adminLevel: new FormControl("", [Validators.required]),
      parent: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
    });


    /* update admin hierarchy */
    this.hierarchyUpdateForm = new FormGroup({
      adminLevel: new FormControl("", [Validators.required]),
      parent: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
    });


    this.hierarchyUpdateForm?.get('adminLevel')?.valueChanges?.subscribe(value => {
      const hierarchyParent = this.hierarchyUpdateForm.get('parent');
      if (value?.id === 1) {
        hierarchyParent?.clearValidators()
        //hierarchyParent?.disable();
      } else {
        hierarchyParent?.setValidators(Validators.required);
        // hierarchyParent?.enable();
      }
      hierarchyParent?.updateValueAndValidity();
      // this.isParentDisabled = false;
    });
  }

  getAdminLevels() {
    this.ngxLoader.start();
    this.adminLevelService.findAll().subscribe((resp: any) => {
      this.adminLevels = resp.data;
      this.ngxLoader.stop();
    });
  }

  onCloseModel() {
    this.isSubmitted = false;
    this.parentVisible = true;
    this.formErrors = [];
    this.hierarchyUpdateForm.reset();
    this.hierarchyAddForm.reset();
  }

  getAdminHierarchy() {
    this.ngxLoader.start();
    this.adminHierarchyService.findAll().subscribe((resp: any) => {
      this.adminHierarchyItems = resp.data;
      this.filteredRequests = resp.data;
      this.ngxLoader.stop();
    });
  }

  loadHierarchy(event: any) {

    const selectedAdmin = event;
    let parentLevel = 1;
    if (event.parent_id) {
      parentLevel = event.parent_id;
    }


    console.log("hierarchy :", selectedAdmin);

    if (selectedAdmin && selectedAdmin.id == 1) {
      this.parentVisible = false;
      this.parentDisable = true;
      this.filterHierarchy(parentLevel);
      this.hierarchyAddForm.get('parent')?.reset();
      this.hierarchyAddForm.get('parent')?.disable();
    }
    // else if (selectedAdmin && selectedAdmin.id == 2) {
    //   this.filterHierarchy(parentLevel);
    //   this.parentDisable = true;
    //   this.parentVisible = true;
    //   // this.hierarchyAddForm.get('parent')?.reset();
    //   // this.hierarchyAddForm.get('parent')?.disable();
    // } 
    else {
      this.filterHierarchy(parentLevel);
      this.parentVisible = true;
      this.parentDisable = false;
      this.hierarchyAddForm.get('parent')?.enable();
    }
    // this.hierarchyAddForm.patchValue({
    //   parent: null
    // });

  }

  filterHierarchy(parentId: any) {

    if (!parentId) {
      this.filteredHierarchyItems = [];
    }
    else {
      this.filteredHierarchyItems = this.adminHierarchyItems.filter((hierarchy: any) => hierarchy.administrative_level_id === parentId);
    }

  }

  loadHierarchyUpdate(event: any) {

    const selectedAdmin = event;
    let parentLevel = 1;
    if (event.parent_id) {
      parentLevel = event.parent_id;
    }


    console.log("hierarchy :", selectedAdmin);

    if (selectedAdmin && selectedAdmin.id == 1) {
      this.parentVisible = false;
      this.parentDisable = true;
      this.filterHierarchy(parentLevel);
      this.hierarchyUpdateForm.get('parent')?.reset();
      this.hierarchyUpdateForm.get('parent')?.disable();
    }
    else {
      this.filterHierarchy(parentLevel);
      this.parentVisible = true;
      this.parentDisable = false;
      this.hierarchyUpdateForm.get('parent')?.enable();
    }
    // this.hierarchyUpdateForm.patchValue({
    //   parent: null
    // });
    // if (!event) {
    //   this.filteredHierarchyItems = [];
    // }
    // else {
    //   this.filteredHierarchyItems = this.adminHierarchyItems.filter((hierarchy: any) => hierarchy.administrative_level_id === event.id);
    // }
  }

  // for add Modal
  openHierarchyModal(content: any) {
    this.parentVisible = true;
    // this.modalService.open(content);
    const modalOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
    };

    this.modalRef = this.modalService.open(content, modalOptions);
    this.modalRef.dismissed.subscribe((reason => {
      this.isSubmitted = false;
      this.formErrors = [];
      this.hierarchyAddForm.reset();
    }))
  }

  //for Update hierarchy Modal
  openHierarchyUpdateModal(isModalOpen: any, hierarchyData: any) {
    this.parentVisible = true;
    const modalOptions_2: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
    };

    this.modalRef = this.modalService.open(isModalOpen, modalOptions_2);
    this.modalRef?.dismissed.subscribe((reason) => {
      this.isSubmitted = false;
      this.formErrors = [];
      this.hierarchyUpdateForm.reset();
    })
    this.updateTheAdminHirachy(hierarchyData);

  }

  updateTheAdminHirachy(hierarchyData: any) {
    this.hierarchyId = hierarchyData.id;
    let patchValues = this.hierarchyUpdateForm.patchValue({
      adminLevel: this.adminLevels.find((level: any) => level.id === hierarchyData.administrative_level_id),
      parent: this.adminHierarchyItems.find((item: any) => item.id === hierarchyData.parent_id),
      name: hierarchyData.name
    });

    if (hierarchyData.administrative_level_id == 1) {
      this.parentVisible = false;
    }
  }



  generateBreadcrumb(hierarchy: any) {

  }

  // check the error key after boolean
  hasErrorFormCheck(key: string): boolean {
    return this.formErrors.some((err => err.key === key)); // check only boolean
  }

  //filter the form array and check
  filterErrorsByKey(key: string): { key: string, value: string }[] {
    return this.formErrors.filter(error => error.key === key); // return new array
  }

  openDesignationModal(content: any) {

  }

  updateHierarchyOnsubmit() {
    this.isSubmitted = true;
    if (this.hierarchyUpdateForm.valid) {
      this.submittedData = true;
      this.ngxLoader.start();
      const updatePayLoad = {
        administrative_level_id: this.hierarchyUpdateForm?.value?.adminLevel?.id,
        name: this.hierarchyUpdateForm?.value?.name,
        parent_id: this.hierarchyUpdateForm.value.parent?.id
      }
      this.adminHierarchyService.update(this.hierarchyId, updatePayLoad).subscribe({
        next: (res: any) => {
          this.submittedData = false;
          this.isSubmitted = false;
          this.hierarchyUpdateForm.reset();
          this.getAdminHierarchy();
          this.getAdminLevels();
          this.modalService.dismissAll("close");
          this.toastr.success(res.message ? res.message : "Record updated successfully", 'Success');
          this.ngxLoader.stop();
        }, error: (error: HttpErrorResponse) => {
          this.submittedData = false;
          this.isSubmitted = false;
          this.formErrors = [];
          if (error.status === 422 && error.error) {
            console.log('Only error :', error.error);

            for (const [key, value] of Object.entries(error?.error)) {// loop the error object
              if (typeof value === 'object' && value != null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                  this.formErrors.push({ key: nestedKey, value: nestedValue as string })
                  this.submittedData = false;
                }
              } else {
                this.formErrors.push({ key: key, value: value as string })
                this.submittedData = false;
              }
            }
          } else {
            this.toastr.error(error.message || "Something went wrong, Please try again later.", "Error!");
          }
          this.ngxLoader.stop();

        }
      })
    }
  }


  createNew() {
    this.isSubmitted = true;
    if (this.hierarchyAddForm.valid) {
      this.submittedData = true;
      this.ngxLoader.start();
      const payload = {
        administrative_level_id: this.hierarchyAddForm.value.adminLevel?.id,
        name: this.hierarchyAddForm.value.name,
        parent_id: this.hierarchyAddForm.value.parent?.id,
      };

      this.adminHierarchyService.create(payload).subscribe({
        next: (res: any) => {
          this.submittedData = false;
          this.isSubmitted = false;
          this.hierarchyAddForm.reset();
          this.getAdminHierarchy();
          this.getAdminLevels();
          this.modalService.dismissAll("close");
          this.toastr.success(res.message ? res.message : "Record added successfully", 'Success');
          this.ngxLoader.stop();
        },
        error: (error: HttpErrorResponse) => {
          this.submittedData = false;
          this.isSubmitted = false;
          this.formErrors = [];
          if (error.status === 422 && error.error) {
            //   console.log('Only error :', error.error);
            for (const [key, value] of Object.entries(error?.error)) {// loop the error object
              if (typeof value === 'object' && value != null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                  this.formErrors.push({ key: nestedKey, value: nestedValue as string })
                  this.submittedData = false;
                }
              } else {
                this.formErrors.push({ key: key, value: value as string })
                this.submittedData = false;
              }
            }
          } else {
            this.toastr.error(error.message || "Something went wrong, Please try again later.", "Error!");
          }
          this.ngxLoader.stop();
        },
      });
    }
  }

  /* delete hirer */
  deleteAdminHierarchy(event: Event, id: string, name: string) {

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: 'Are you sure?',
      message: `${name}`,
      icon: 'pi pi-question',
      accept: () => {
        this.accept(id);
      },
      reject: () => {
        this.reject();
      }
    });
  }

  accept(id: string): void {
    this.ngxLoader.start()
    this.adminHierarchyService.delete(id).subscribe({
      next: (response: ApiResponse) => {
        this.getAdminHierarchy();
        this.toastr.success('Admin Hierarchy deleted', 'Success');
        this.ngxLoader.stop()
      }, error: (err: HttpErrorResponse) => {
        this.ngxLoader.stop()
      }
    })
  }

  reject(): void {
    this.confirmationService.close();
  }

}
