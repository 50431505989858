<!-- <div class="card col-lg-12">
  <div class="card-body">
      <div class="row mt-5">
      <div class="mt-n5">
        <div class="p-2 d-flex justify-content-start align-items-center">
          <div class="text-start">
            <div
              class="profile-user position-relative d-inline-block mx-auto mb-4"
            >
              <img
                [src]="
                  this.setImagePath !== undefined
                    ? this.setImagePath
                    : 'assets/images/cgl/logo/user.png'
                "
                class="rounded-circle avatar-xl img-thumbnail user-profile-image"
                id="product-img"
                alt="user-profile-image"
              />
              <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                <input
                  id="profile-img-file-input"
                  type="file"
                  class="profile-img-file-input"
                  accept="image/png,image/jpeg,image/jpg"
                  (change)="fileChange($event)"
                />
                <label
                  for="profile-img-file-input"
                  class="profile-photo-edit avatar-xs"
                >
                  <span class="avatar-title rounded-circle bg-light text-body">
                    <i class="ri-camera-fill"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div style="padding-bottom: 20px" class="px-4">
            <h5 class="fs-16 mb-1">
              {{ loginUserFirstName | capitalize }}
              {{ (loginUserMiddleName | capitalize) ?? "" }}
            </h5>
            <p class="text-muted mb-0">{{ userRole | capitalize }}</p>
          </div>
        </div>
      </div>
    </div> 
  </div>
</div> -->

<div class="card col-lg-12">
  <div class="card-body">
    <!--image container start  -->
    <!--  <h4 class="card-title mb-0 flex-grow-1 mb-5">Change Profile Details</h4> -->
    <div class="row mt-5">
      <div class="mt-n5">
        <div class="p-2 d-flex justify-content-start align-items-center">
          <div class="text-start">
            <div
              class="profile-user position-relative d-inline-block mx-auto mb-4"
            >
              <img
                [src]="
                  this.setImagePath !== undefined
                    ? this.setImagePath
                    : 'assets/images/cgl/logo/user.png'
                "
                class="rounded-circle avatar-xl img-thumbnail user-profile-image"
                id="product-img"
                alt="user-profile-image"
              />
              <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                <input
                  id="profile-img-file-input"
                  type="file"
                  class="profile-img-file-input"
                  accept="image/png,image/jpeg,image/jpg"
                  (change)="fileChange($event)"
                />

                <label
                  for="profile-img-file-input"
                  class="profile-photo-edit avatar-xs"
                >
                  <span class="avatar-title rounded-circle bg-light text-body">
                    <i class="ri-camera-fill"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div style="padding-bottom: 20px" class="px-4">
            <h5 class="fs-16 mb-1">
              {{ loginUserFirstName | capitalize }}
              {{ (loginUserMiddleName | capitalize) ?? "" }}
            </h5>
            <p class="text-muted mb-0">{{ userRole | capitalize }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--image container close  -->
    <form [formGroup]="profileUpdateForm" (submit)="onUpdateUserProfile()">
      <div class="row">
        <!--  <h4 class="card-title mb-0 flex-grow-1 mb-4">Change Profile Details</h4> -->
        <div class="col-lg-12">
          <div class="mb-2">
            <label for="firstnameInput" class="form-label"
              >User Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="firstnameInput"
              appMaxInputLimit
              [maxLength]="45"
              formControlName="userName"
              placeholder="Enter user name"
            />
            <small
              class="text-danger m-0"
              *ngIf="
                isSubmitted &&
                this.profileUpdateForm.get('userName')?.hasError('required')
              "
              >User name is required</small
            >
          </div>
        </div>
        <!--end col-->

        <!--end col-->

        <!-- end col -->

        <div class="custom-tr text-end">
          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light mt-3"
          >
            Update Personal Details
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="card col-lg-12">
  <div class="card-body">
    <form [formGroup]="passwordResetForm" (submit)="resetPasswordOnAction()">
      <div class="row">
        <h4 class="card-title mb-0 mb-4">Change Password</h4>
        <div class="col-lg-4">
          <div class="mb-2 position-relative">
            <label for="currentPasswords" class="form-label"
              >Current Password <span class="text-danger">*</span></label
            >
            <div class="position-relative">
              <input
                [type]="fieldTextType ? 'text' : 'password'"
                class="form-control"
                id="currentPasswords"
                appMaxInputLimit
                [maxLength]="45"
                formControlName="currentPassword"
                placeholder="Enter current password"
              />
              <i
                [ngClass]="{
                  'mdi-eye-off-outline': !fieldTextType,
                  'mdi-eye-outline': fieldTextType
                }"
                class="mdi align-middle eye-icon"
                (click)="eyeOnActionCurrent()"
              ></i>
            </div>
            <small
              class="text-danger m-0"
              *ngIf="
                isSubmittedData &&
                this.passwordResetForm
                  .get('currentPassword')
                  ?.hasError('required')
              "
              >Current password is required</small
            >
            <span *ngIf="hasErrorFormCheck('current_password')">
              <app-error
                [errors]="filterErrorsByKey('current_password')"
              ></app-error>
            </span>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mb-2 position-relative">
            <label for="phonenumberInput" class="form-label"
              >New Password <span class="text-danger">*</span></label
            >
            <div class="position-relative">
              <input
                [type]="fieldTextTypeNew ? 'text' : 'password'"
                class="form-control"
                formControlName="newPassword"
                id="phonenumberInput"
                appMaxInputLimit
                [maxLength]="45"
                placeholder="Enter new password"
                [(ngModel)]="newPassword"
                (ngModelChange)="passwordValidate($event)"
              />
              <i
                [ngClass]="{
                  'mdi-eye-off-outline': !fieldTextTypeNew,
                  'mdi-eye-outline': fieldTextTypeNew
                }"
                class="mdi align-middle eye-icon"
                (click)="eyeOnActionNew()"
              ></i>
            </div>
            <small
              class="text-danger m-0"
              *ngIf="
                isSubmittedData &&
                this.passwordResetForm.get('newPassword')?.hasError('required')
              "
              >New password is required</small
            >
            <span *ngIf="hasErrorFormCheck('new_password')">
              <app-error
                [errors]="filterErrorsByKey('new_password')"
              ></app-error>
            </span>
            @if (this.newPassword) {
            <small class="text-danger m-0" *ngIf="passwordError">{{
              passwordError
            }}</small>
            }
            <small class="m-0 success_password" *ngIf="this.isSuccessPassword"
              >{{ passwordSuccess }}
            </small>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mb-2 position-relative">
            <label for="confirmPasseword" class="form-label"
              >Password Confirmation <span class="text-danger">*</span></label
            >
            <div class="position-relative">
              <input
                [type]="fieldTextTypeConfirm ? 'text' : 'password'"
                formControlName="conformPassword"
                class="form-control"
                id="confirmPasseword"
                appMaxInputLimit
                [maxLength]="45"
                placeholder="Confirm password"
              />
              <i
                [ngClass]="{
                  'mdi-eye-off-outline': !fieldTextTypeConfirm,
                  'mdi-eye-outline': fieldTextTypeConfirm
                }"
                class="mdi align-middle eye-icon"
                (click)="eyeOnActionConfirm()"
              ></i>
            </div>
            <small
              class="text-danger m-0"
              *ngIf="
                isSubmittedData &&
                this.passwordResetForm
                  .get('conformPassword')
                  ?.hasError('required')
              "
              >Confirm password is required</small
            >
            <span *ngIf="hasErrorFormCheck('new_password_confirmation')">
              <app-error
                [errors]="filterErrorsByKey('new_password_confirmation')"
              ></app-error>
            </span>
          </div>
        </div>
        <div class="custom-tr text-end">
          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light mt-3 custom_widthSet"
          >
            Change Password
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
