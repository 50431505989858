import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTextInput]'
})
export class TextInputDirective {
  @Input('appTextInput') inputType: 'text' | 'nic' | 'mobile' | 'landline' | 'email' = 'text';

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string): void {
    let filteredValue = value;

    if (this.inputType === 'text') {
      filteredValue = value.replace(/[^A-Za-z\s.]/g, ''); // only letters 

    } else if (this.inputType === 'nic') { // nic

      filteredValue = value.replace(/[^0-9vVxX]/g, '');
      /*   const nicRegex = /^([0-9]{9}[xXvV]|[0-9]{12})$/;
  
        if (!nicRegex.test(filteredValue)) {
          filteredValue = filteredValue.slice(0, 12);
        } */
      filteredValue = value.replace(/[^0-9vVxX]/g, '');

      const vIndex = filteredValue.search(/[vV]/);

      if (vIndex !== -1) {
        filteredValue = filteredValue.slice(0, vIndex + 1).replace(/[^0-9vVxX]/g, '') + filteredValue.slice(vIndex + 1).replace(/[0-9]/g, '');
      }
      const nicRegex = /^([0-9]{9}[xXvV]|[0-9]{12})$/;
      if (!nicRegex.test(filteredValue)) {
        filteredValue = filteredValue.slice(0, 12);
      }
    } else if (this.inputType === 'mobile') {

      filteredValue = value.replace(/[^0-9]/g, '');
      const mobileRegex = /^07[0-9]{8}$/;

      if (!mobileRegex.test(filteredValue)) {
        filteredValue = filteredValue.slice(0, 10);
      }
      /* --------------------------------------------------------------- */
    } else if (this.inputType === 'email') {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(filteredValue)) {
        filteredValue = value.replace(/[^a-zA-Z0-9._%+-@]/g, '');
      }
    }
    this.ngControl.control?.setValue(filteredValue, { emitEvent: false });
  }
}

