import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../core/models/api-response.model';
import { env } from 'process';

@Injectable({ providedIn: 'root' })
export class QuotationService {

    private http = inject(HttpClient);
    private API_URL_PREFIX: string = environment.apiURL + '/' + 'admin/quotations';

    private API_URL_PREFIX_REQ: string = environment.apiURL + '/' + 'admin/' // quotation request
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    findAll(queryParams?: any) {
        let params = new HttpParams();
        if (queryParams) {
            for (const key in queryParams) {
                if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
                    params = params.set(key, queryParams[key]);
                }
            }
        }

        return this.http.get(this.API_URL_PREFIX + '?branch_id=' + queryParams, {
            headers: this.httpOptions.headers,
            params: params
        });
    }


    findAllRequestQuotation(queryParams?: any) {
        let params = new HttpParams();
        if (queryParams) {
            for (const key in queryParams) {
                if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
                    params = params.set(key, queryParams[key]);
                }
            }
        }
        return this.http.get(this.API_URL_PREFIX_REQ + 'quotation-requests', {
            headers: this.httpOptions.headers,
            params: params
        });
    }


    findById(id: string) {
        return this.http.get(this.API_URL_PREFIX + '/' + id, this.httpOptions);
    }

    create(payload: any): Observable<any> {
        return this.http.post(this.API_URL_PREFIX, payload, { responseType: 'blob' });
    }

    // create(payload: any) : Observable<Blob>{
    //     return this.http.post(this.API_URL_PREFIX, payload, {responseType: 'blob'});
    // }

    getPdfFile(id: string): Observable<Blob> {
        return this.http.get(this.API_URL_PREFIX + '/' + id + '/file', { responseType: 'blob' });
    }

    update(id: any, data: any): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.API_URL_PREFIX + '/' + id, data);
    }

    delete(id: any): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.API_URL_PREFIX + '/' + id);
    }
}
