import { Component, inject, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { FeatherModule } from "angular-feather";
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { NgSelectModule } from "@ng-select/ng-select";
import { CustomerService } from 'src/app/services/customer.service';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { TagModule } from 'primeng/tag';



@Component({
  selector: 'app-customer-audience',
  standalone: true,
  imports: [CommonModule, TagModule, SharedModule, ButtonModule, ConfirmDialogModule, TableModule, FeatherModule, FormsModule, NgSelectModule],
  templateUrl: './customer-audience.component.html',
  styleUrl: './customer-audience.component.scss',
  providers: [ConfirmationService],
})
export class CustomerAudienceComponent implements OnInit {


  private modalService = inject(NgbModal);
  private ngxLoader = inject(NgxUiLoaderService);
  private toastr = inject(ToastrService);
  private router = inject(Router); // Inject Router
  private confirmationService = inject(ConfirmationService);
  private customerService = inject(CustomerService)



  filteredRequests: any
  customerList: any;
  viewModalData: any
  modalRef!: any;
  searchValue: any
  statusItem = [{ name: 'Activate', value: true }, { name: 'Deactivate', value: false }];


  ngOnInit(): void {
    this.getAllCustomer();
  }

  getAllCustomer() {
    this.ngxLoader.start();
    this.customerService.getallCustomer().subscribe({
      next: (res: ApiResponse) => {
        console.log(res);
        this.filteredRequests = res.data;
        this.customerList = res.data;
      }, error: (error: HttpErrorResponse) => {
        throw error;
      }, complete: () => {
        this.ngxLoader.stop();
      }
    })
  }

  filteredRequestsSearch() {
    this.filteredRequests = this.customerList.filter((request: any) => {
      return (
        request.full_name?.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    });
  }
  customerData: any;
  openViewModel(modal: any, id: number) {
    //console.log('this is my Id:', id);
    this.ngxLoader.start()
    this.customerService.findById(id).subscribe({
      next: (response: ApiResponse) => {
        this.customerData = response.data;
        console.log('The customer Object:', this.customerData);
        this.ngxLoader.stop();
      }, error: (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
      }, complete: () => {
        this.ngxLoader.stop();
      }
    })

    this.modalRef = this.modalService.open(modal, { size: 'lg' });
  }

  isChange: any;
  currentStatus: any;

  statusChangeCustomer(event: Event, id: number, name: string, status: boolean) {
    //console.log('each Customer :', id);
    //console.log('each Customer status :', status);
    this.isChange = status;
    status == true ? this.currentStatus = 'Activated' : this.currentStatus = 'Deactivate';

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: '',
      message: `Do you want to change the customer status of ${name}?`,
      icon: 'pi pi-question',
      accept: () => {
        this.accept(id, this.isChange);
      },
      reject: () => {
        this.reject();
      }
    });
  }
  onChangeStatus(event: any) {
    this.isChange = event?.value;
  }


  accept(id: number, status: boolean) {
    const payload = {
      status: status
    }
    console.log('Is Payload: ', payload);
    this.ngxLoader.start();
    this.customerService.updateCustomer(id, payload).subscribe({
      next: (response: ApiResponse) => {
        this.getAllCustomer();
        this.toastr.success('Customer status changed successfully', 'Success');
        this.ngxLoader.stop();
      }, error: (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
      }, complete: () => {
        this.ngxLoader.stop();
      }
    })
  }


  reject(): void {
    this.confirmationService.close();
  }

}
