import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../core/models/api-response.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DesignationService {

    private http = inject(HttpClient);
    private API_URL_PREFIX: string = environment.apiURL + '/' + 'admin/designations';
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    findAll(queryParams?: any) {
        let params = new HttpParams();
        if (queryParams) {
            for (const key in queryParams) {
                if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
                    params = params.set(key, queryParams[key]);
                }
            }
        }
        return this.http.get(this.API_URL_PREFIX, {
            headers: this.httpOptions.headers,
            params: params
        });
    }

    getTree() : Observable<any> {
        return this.http.get<ApiResponse>(this.API_URL_PREFIX + '/tree' );
    }

    findById(id: string) {
        return this.http.get(this.API_URL_PREFIX + '/' + id, this.httpOptions);
    }

    create(payload: any) {
        return this.http.post(this.API_URL_PREFIX, payload, this.httpOptions);
    }
  
    update(id:any,data: any): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.API_URL_PREFIX +'/'+ id, data);
    }

    delete(id:any): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.API_URL_PREFIX +'/'+ id);
    }


}
