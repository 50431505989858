<!-- start services -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="text-center mb-5">
                    <h1 class="mb-3 ff-secondary fw-semibold lh-base">A Digital web design studio creating modern & engaging online</h1>
                    <p class="text-muted">To achieve this, it would be necessary to have uniform grammar,
                        pronunciation and more common words. If several languages coalesce the grammar</p>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

        <div class="row g-3">
            @for (data of Services; track $index) {
            <div class="col-lg-4">
                <div class="d-flex p-3">
                    <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm icon-effect">
                            <div class="avatar-title bg-transparent text-success rounded-circle">
                                <i class="{{data.icon}} fs-36"></i>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="fs-18">{{data.title}}</h5>
                        <p class="text-muted my-3 ff-secondary">{{data.content}}</p>
                        <div>
                            <a href="javascript:void(0);" class="fs-13 fw-medium">Learn More <i class="ri-arrow-right-s-line align-bottom"></i></a>
                        </div>
                    </div>
                </div>
            </div>}
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end services -->