import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { TableModule } from 'primeng/table';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { size } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PaymentService } from 'src/app/services/payment.service';
import { ProposalService } from 'src/app/services/proposal.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { CalendarModule } from 'primeng/calendar';
import { DatePipe } from "@angular/common";
import { RouterModule } from '@angular/router';
import { ErrorComponent } from 'src/app/shared/components/errorHandle/error/error.component';
import { SharedModule } from "../../shared/shared.module";


@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [CommonModule,
    FeatherModule,
    TableModule,
    NgSelectModule,
    ReactiveFormsModule,
    CalendarModule,
    RouterModule,
    FormsModule,
    ErrorComponent, SharedModule],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
  providers: [DatePipe]
})
export class PaymentComponent {

  paymentList: any;
  proposalList: any;
  filteredRequests: any;
  paymentAddForm!: FormGroup;
  isSubmitted: boolean = false;
  submittedData: boolean = false;
  selectedPackage: any;
  searchValue: any = "";
  dueAmountHandle: string | undefined | null;
  todayDate: any;
  selectedMethod: any
  isCheckedCheckBox: boolean = false;

  isIncludeEmail: boolean = false;
  downLoadReceipt: boolean = false;
  job_Code: string = '';

  paymentMethods = [
    { name: "Cash", value: "CASH" },
    { name: "Online", value: "ONLINE" },
  ]

  private modalService = inject(NgbModal);
  private ngxLoader = inject(NgxUiLoaderService);
  private paymentService = inject(PaymentService);
  private proposalService = inject(ProposalService);
  private toastr = inject(ToastrService);
  private datePipe = inject(DatePipe);
  modalRef: NgbModalRef | undefined | any;
  formErrors: { key: string, value: string }[] = []; // form error array

  ngOnInit(): void {

    //this.initializeData();
    this.getAllPendingPackages();

    this.paymentAddForm = new FormGroup({
      selectedPackage: new FormControl("", [Validators.required]),
      paidAmount: new FormControl("", [Validators.required]),
      paymentReference: new FormControl("", [Validators.required]),
      paymentDate: new FormControl("", [Validators.required]),
      paymentMethod: new FormControl(null, [Validators.required]),
      note: new FormControl(""),
    });

    //this.selectedMethod=this.paymentMethods[0]
    const today = new Date().toLocaleDateString();
    this.todayDate = today;
  }

  openPaymentModal(content: any, pkg?: any) {

    if (pkg) {
      this.selectedPackage = pkg;
      this.getDueAmount();
      this.isIncludeEmail = pkg?.is_email;
      pkg?.is_email === true ? this.isCheckedCheckBox = true : this.isCheckedCheckBox = false;
      this.job_Code = pkg?.job_code;
    }
    else {
      this.selectedPackage = null;
      this.paymentAddForm.get('paidAmount')?.setValue('');
      this.paymentAddForm.get('paymentDate')?.setValue(this.todayDate);
      this.selectedMethod = this.paymentMethods[0];
      this.isCheckedCheckBox = false;
    }

    const modalRef = this.modalService.open(content, {
      size: 'lg',
      backdrop: true,
      beforeDismiss: () => {
        this.isSubmitted = false;
        this.formErrors = [];
        this.paymentAddForm.reset();
        return true;
      }
    });
    modalRef.dismissed.subscribe((res: any) => {
      this.isCheckedCheckBox = false;
      this.downLoadReceipt = false;
    });
  }




  getDueAmount() {
    const dueAmount = this.selectedPackage.due_amount;
    this.dueAmountHandle = dueAmount;
    this.paymentAddForm.get('paidAmount')?.setValue(this.dueAmountHandle);
    this.paymentAddForm.get('paymentDate')?.setValue(this.todayDate);
    this.selectedMethod = this.paymentMethods[0]
  }

  getAllPendingPackages() {
    this.ngxLoader.start();
    this.proposalService.findPendingPackages().subscribe((resp: any) => {
      this.paymentList = resp.data;
      this.proposalList = resp.data;
      this.filteredRequests = resp.data;
      this.ngxLoader.stop();
    });
  }

  checkOnChangeForReceipt(event: any) {
    let inputElement = event.target as HTMLInputElement;
    this.isCheckedCheckBox = inputElement.checked;  // get value
    console.log('Checkbox changed:', this.isCheckedCheckBox);
  }

  checkOnChangeDownLoadReceipt(event: any) {
    let downLoadReceiptRef = event.target as HTMLInputElement;
    this.downLoadReceipt = downLoadReceiptRef.checked;  // get value
    console.log('check', this.downLoadReceipt);
  }
  /*   getAllPayments() {
      this.paymentService.findAll().subscribe((resp: any) => {
        //console.log(resp);
        // this.paymentList = resp.data;
        // this.filteredRequests = resp.data;
      });
    } */

  filteredRequestsHandle() {
    this.filteredRequests = this.paymentList.filter((request: any) => {
      return (
        request.job_code.toLowerCase().includes(this.searchValue.toLowerCase())
      )
    });
  }

  // getAllProposals() {

  //   this.proposalService.findAll().subscribe((resp: any) => {
  //     this.proposalList = resp.data;

  //   });
  // }
  // check the error key after boolean
  hasErrorFormCheck(key: string): boolean {
    return this.formErrors.some((err => err.key === key)); // check only boolean
  }

  //filter the form array and check
  filterErrorsByKey(key: string): { key: string, value: string }[] {
    return this.formErrors.filter(error => error.key === key); // return new array
  }


  addNewPayment() {
    this.isSubmitted = true;
    // this.scrollToFirstErrorNonApiCall();
    console.log("form", this.paymentAddForm.value.downLoadReceipt);
    console.log('Is email', this.isIncludeEmail);


    if (this.paymentAddForm.valid) {
      this.submittedData = true;

      let payload = {};
      const paymentDate = this.paymentAddForm.get('paymentDate')?.value;
      const FormatePaymentDate = this.datePipe.transform(paymentDate, 'yyyy-MM-dd');

      payload = {
        user_package_id: this.paymentAddForm.value.selectedPackage.id,
        amount: this.paymentAddForm.value.paidAmount,
        reference: this.paymentAddForm.value.paymentReference,
        payment_date: FormatePaymentDate,
        payment_method: this.paymentAddForm.value.paymentMethod.value,
        send_receipt: this.isCheckedCheckBox,
        download_receipt: this.downLoadReceipt,
        note: this.paymentAddForm.value.note,
      };

      console.log("payment", payload);

      this.ngxLoader.start();
      this.paymentService.create(payload).subscribe({
        next: (res: any) => {
          this.isSubmitted = false;
          this.submittedData = false;

          this.toastr.success("Payment created successfully", 'Success');
          this.getAllPendingPackages();

          if (this.downLoadReceipt === true) {
            const blob = new Blob([res], { type: "application/pdf" })
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = "Receipt_" + this.job_Code + "_" + this.todayDate + ".pdf";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(a);
          }
          this.paymentAddForm.reset();
          this.modalService.dismissAll("close");
          this.ngxLoader.stop();
        },
        error: (error: HttpErrorResponse) => {
          this.paymentAddForm.reset();
          this.isSubmitted = false;
          this.submittedData = false;
          this.formErrors = [];
          if (error.status === 422 && error.error) {
            console.log('employee add error', error.error);

            for (const [key, value] of Object.entries(error?.error)) {
              if (typeof value === 'object' && value != null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                  this.formErrors.push({ key: nestedKey, value: nestedValue as string });
                  this.submittedData = false;
                }
              } else {
                this.formErrors.push({ key: key, value: value as string })
                this.submittedData = false;

              }
            }
          } else {
            this.toastr.error(error.message || "Something went wrong, Please try again later.", "Error!");
          }
          this.ngxLoader.stop();
        },
      });
    } else {
      this.ngxLoader.stop();
    }
  }

  onPackageSelect(event: any): void {
    console.log('The Event', event);

    this.selectedPackage = event;
    if (event) {
      this.paymentAddForm.get('paidAmount')?.setValue(event.due_amount);
      this.isCheckedCheckBox = true;
    } else {
      this.paymentAddForm.get('paidAmount')?.setValue('');
      this.isCheckedCheckBox = false;
    }
  }
  closeModal() {
    this.paymentAddForm.reset();
    this.isSubmitted = false;
    this.submittedData = false;
    this.formErrors = [];
  }
}
