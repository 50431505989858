<section class="section" id="findJob">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7">
                <div class="text-center mb-5">
                    <h1 class="mb-3 ff-secondary fw-semibold text-capitalize lh-base">Find Your <span class="text-primary">Career</span> You Deserve it</h1>
                    <p class="text-muted">Post a job to tell us about your project. We'll quickly match you with the
                        right freelancers.</p>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

        <div class="row">
            @for (job of findjobs; track $index) {
            <div class="col-lg-6">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="avatar-sm">
                                <div class="avatar-title bg-warning-subtle rounded">
                                    <img src="{{job.logo}}" alt="" class="avatar-xxs">
                                </div>
                            </div>
                            <div class="ms-3 flex-grow-1">
                                <a href="javascript:void(0);">
                                    <h5>{{job.name}}</h5>
                                </a>
                                <ul class="list-inline text-muted mb-3">
                                    <li class="list-inline-item">
                                        <i class="ri-building-line align-bottom me-1"></i> {{job.company}}
                                    </li>
                                    <li class="list-inline-item">
                                        <i class="ri-map-pin-2-line align-bottom me-1"></i> {{job.location}}
                                    </li>
                                    <li class="list-inline-item">
                                        <i class="ri-money-dollar-circle-line align-bottom me-1"></i> {{job.money}}
                                    </li>
                                </ul>
                                <div class="hstack gap-2">
                                    @for (skill of job.skill; track $index) {
                                    <span class="badge" [ngClass]="{'bg-success-subtle text-success': skill == 'Design' || skill == 'Manager' || skill == 'Full Time',
                                    'bg-danger-subtle text-danger': skill == 'UI/UX' || skill == 'HR',
                                    'bg-primary-subtle text-primary':skill == 'Adobe XD' || skill == 'Sales' || skill == 'Fashion',
                                    'bg-secondary-subtle text-secondary': skill == 'Product','bg-info-subtle text-info': skill == 'Bussiness' || skill == 'Remote',
                                    'bg-warning-subtle text-warning':skill == 'Marketing'}">{{skill}}</span>
                                    }
                                </div>
                            </div>
                            <div>
                                <button type="button" class="btn btn-ghost-primary btn-icon custom-toggle" data-bs-toggle="button">
                                    <i class="icon-on" [ngClass]="job.bookmark == true ? 'ri-bookmark-fill':'ri-bookmark-line'"></i>
                                    <!-- <span class="icon-on"><i class="ri-bookmark-line"></i></span>
                                    <span class="icon-off"><i class="ri-bookmark-fill"></i></span> -->
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div class="col-lg-12">
                <div class="text-center mt-4">
                    <a href="javascript:void(0);" class="btn btn-ghost-primary">View More Jobs <i class="ri-arrow-right-line align-bottom"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- start find jobs -->
<section class="section">
    <div class="container">
        <div class="row align-items-center gy-4">
            <div class="col-lg-6 order-2 order-lg-1">
                <div class="text-muted mt-5 mt-lg-0">
                    <h5 class="fs-12 text-uppercase text-success">Hot Featured Company</h5>
                    <h1 class="mb-3 ff-secondary fw-semibold text-capitalize lh-base">Get <span class="text-primary">10,000+</span> Featured Companies</h1>
                    <p class="ff-secondary mb-2">The demand for content writing services is growing. This is because
                        content is required in almost every industry. <b>Many companies have discovered how effective
                            content marketing is.</b> This is a major reason for this increase in demand.</p>
                    <p class="mb-4 ff-secondary">A Content Writer is a professional who writes informative and engaging
                        articles to help brands showcase their products.</p>

                    <div class="mt-4">
                        <a routerLink="/" class="btn btn-primary">View More Companies <i class="ri-arrow-right-line align-middle ms-1"></i></a>
                    </div>
                </div>
            </div>
            <!-- end col -->
            <div class="col-lg-4 col-sm-7 col-10 ms-lg-auto mx-auto order-1 order-lg-2">
                <div>
                    <div class="card shadow-lg">
                        <div class="card-body">
                            <button type="button" class="btn btn-icon btn-soft-primary float-end" data-bs-toggle="button" aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            <div class="avatar-sm mb-4">
                                <div class="avatar-title bg-secondary-subtle rounded">
                                    <img src="assets/images/companies/img-1.png" alt="" class="avatar-xxs">
                                </div>
                            </div>
                            <a href="javascript:void(0);">
                                <h5>New Web designer</h5>
                            </a>
                            <p class="text-muted">Themesbrand</p>

                            <div class="d-flex gap-4 mb-3">
                                <div>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i>
                                    Escondido,California
                                </div>

                                <div>
                                    <i class="ri-time-line text-primary me-1 align-bottom"></i> 3 min ago
                                </div>
                            </div>

                            <p class="text-muted">As a Product Designer, you will work within a Product Delivery Team
                                fused with UX, engineering, product and data talent.</p>

                            <div class="hstack gap-2">
                                <span class="badge bg-success-subtle text-success">Full Time</span>
                                <span class="badge bg-primary-subtle text-primary">Freelance</span>
                                <span class="badge bg-danger-subtle text-danger">Urgent</span>
                            </div>

                            <div class="mt-4 hstack gap-2">
                                <a href="javascript:void(0);" class="btn btn-soft-primary w-100">Apply Job</a>
                                <a href="javascript:void(0);" class="btn btn-soft-success w-100">Overview</a>
                            </div>
                        </div>
                    </div>
                    <div class="card shadow-lg bg-info mb-0 features-company-widgets rounded-3">
                        <div class="card-body">
                            <h5 class="text-white fs-16 mb-4">10,000+ Featured Companies</h5>

                            <div class="d-flex gap-1">
                                <a href="javascript: void(0);" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Brent Gonzalez">
                                    <div class="avatar-xs">
                                        <div class="avatar-title bg-light bg-opacity-25 rounded-circle">
                                            <img src="assets/images/companies/img-5.png" alt="" height="15">
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript: void(0);" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Brent Gonzalez">
                                    <div class="avatar-xs">
                                        <div class="avatar-title bg-light bg-opacity-25 rounded-circle">
                                            <img src="assets/images/companies/img-2.png" alt="" height="15">
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript: void(0);" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Brent Gonzalez">
                                    <div class="avatar-xs">
                                        <div class="avatar-title bg-light bg-opacity-25 rounded-circle">
                                            <img src="assets/images/companies/img-8.png" alt="" height="15">
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript: void(0);" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Brent Gonzalez">
                                    <div class="avatar-xs">
                                        <div class="avatar-title bg-light bg-opacity-25 rounded-circle">
                                            <img src="assets/images/companies/img-7.png" alt="" height="15">
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript: void(0);" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="More Companies">
                                    <div class="avatar-xs">
                                        <div class="avatar-title fs-11 rounded-circle bg-light bg-opacity-25 text-white">
                                            1k+
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end find jobs -->