import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [CommonModule,ConfirmDialogModule],
  providers: [ConfirmationService],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent {
  @Output() approve = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  @ViewChild('cd') cd: any;

  header!: string;
  message!: string;

  constructor(private confirmationService: ConfirmationService) {}

  show(header: string, message: string) {
    this.header = header;
    this.message = message;

    this.confirmationService.confirm({
      header: this.header,
      message: this.message,
      accept: () => this.onApprove(),
      reject: () => this.onCancel()
    });
  }

  onApprove() {
    this.approve.emit();
    this.cd.hide();
  }

  onCancel() {
    this.cancel.emit();
    this.cd.hide();
  }
}
