import { Component, ViewChild, inject, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfirmationService, TreeNode, MenuItem } from 'primeng/api';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { FormControl, FormGroup, ReactiveFormsModule, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DesignationService } from 'src/app/services/designation.service';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FeatherModule } from "angular-feather";
import { ConfirmPopup, ConfirmPopupModule } from 'primeng/confirmpopup';
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorComponent } from 'src/app/shared/components/errorHandle/error/error.component';

@Component({
  selector: 'app-all-designations',
  standalone: true,
  imports: [CommonModule, SharedModule, OrganizationChartModule, ConfirmDialogModule, ReactiveFormsModule, NgSelectModule, FeatherModule, ConfirmPopupModule, ContextMenuModule, ErrorComponent],
  providers: [ConfirmationService],
  templateUrl: './all-designations.component.html',
  styleUrl: './all-designations.component.scss',
})
export class AllDesignationsComponent implements AfterViewInit {

  breadCrumbItems!: Array<{}>;
  designationAddForm!: FormGroup;
  designationUpdateForm!: FormGroup;
  isSubmitted: boolean = false;
  designationList: any;
  superiors: any;
  superiorDesignation: any;
  selectedDesignation: any;
  selectedNodes!: TreeNode[];
  parentId: any;
  submittedData: boolean = false;
  selectedId: any;
  zoomLevel: number = 0.5;
  styleClass: any;
  items: MenuItem[] | undefined;
  isOpenContextMenu: boolean = false;
  reportingSuperior: boolean = false;
  formErrors: { key: string, value: string }[] = []; // form error array

  private ngxLoader = inject(NgxUiLoaderService);


  @ViewChild('zoom') zoomElement!: ElementRef;



  panning: boolean = false;
  pointX: number = 0;
  pointY: number = 0;
  start = { x: 0, y: 0 };
  scale: number = 0.6;

  @ViewChild("designationEditModal") editDataModal: any;
  private modalService = inject(NgbModal);
  private designationService = inject(DesignationService);
  private toastr = inject(ToastrService);
  private confirmationService = inject(ConfirmationService);
  @ViewChild("contextmenu") contextmenu: ContextMenu | undefined;
  @ViewChild("conformPop") conformPop: ConfirmPopup | undefined;

  designationData: TreeNode[] = [];
  selectedNode: TreeNode | null = null;

  modalRef: NgbModalRef | undefined;

  private readonly maxValue: number = 100;



  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Designations", active: true },
    ];
    this.getAllDesignationsList();
    this.getTreeData();
    this.menuDataHandle();
    this.designationAddForm = new FormGroup({
      designationName: new FormControl("", [Validators.required]),
      superiorDesignation: new FormControl("", [Validators.required]),
      orcPercentage: new FormControl("", [Validators.required, Validators.min(0), Validators.max(100)]),
      designationCode: new FormControl("", [Validators.required]),
    });

    this.designationUpdateForm = new FormGroup({
      designationName: new FormControl("", [Validators.required]),
      superiorDesignation: new FormControl("", [Validators.required]),
      orcPercentage: new FormControl("", [Validators.required, Validators.min(0), Validators.max(100)]),
      designationCode: new FormControl("", [Validators.required]),
    });
    // this.scale=0.5
    //console.log('start', this.start);
  }
  /* --------------- */
  ngAfterViewInit(): void {
    const zoom = this.zoomElement.nativeElement;
    const sidebarMargin = 290; // Sidebar margin in pixels
    const topBarMargin = 243;

    zoom.onmousedown = (e: MouseEvent) => {
      e.preventDefault();
      this.start = {
        x: e.clientX - sidebarMargin - this.pointX,
        y: e.clientY - topBarMargin - this.pointY
      };
      this.panning = true;

      //console.log('Mouse down:', { start: this.start, panning: this.panning });
    };

    zoom.onmouseup = () => {
      this.panning = false;
      //console.log('Mouse up:', { panning: this.panning });
    };

    zoom.onmousemove = (e: MouseEvent) => {
      e.preventDefault();
      if (!this.panning) {
        return;
      }
      this.pointX = e.clientX - sidebarMargin - this.start.x;
      this.pointY = e.clientY - topBarMargin - this.start.y;
      this.setTransform();
      // console.log('Mouse move:', { pointX: this.pointX, pointY: this.pointY });
      if (this.contextmenu) {
        this.contextmenu.hide();
      }
    };

    zoom.onwheel = (e: WheelEvent) => {
      e.preventDefault();
      const xs = (e.clientX - sidebarMargin - this.pointX) / this.scale;
      const ys = (e.clientY - topBarMargin - this.pointY) / this.scale;

      const delta = -e.deltaY;
      this.scale *= delta > 0 ? 1.2 : 1 / 1.2;
      this.pointX = e.clientX - sidebarMargin - xs * this.scale;
      this.pointY = e.clientY - topBarMargin - ys * this.scale;

      this.setTransform();
      /*   console.log('Wheel event:', {
          delta: delta,
          scale: this.scale,
          pointX: this.pointX,
          pointY: this.pointY,
          xs: xs,
          ys: ys
        }); */
      if (this.contextmenu) {
        this.contextmenu.hide();
      }
    };
  }
  setTransform() {
    const zoom = this.zoomElement.nativeElement;
    zoom.style.transform = `translate(${this.pointX}px, ${this.pointY}px) scale(${this.scale})`;
  }
  /* ---------------- */

  onOrcPercentageInput(event: any) {

    const input = event.target as HTMLInputElement;
    let value = parseInt(input.value, 10);

    if (value > this.maxValue) {
      input.value = this.maxValue.toString();
      event.preventDefault();
    }
  }


  menuDataHandle() {
    this.items = [
      {
        label: 'Update', icon: 'pi pi-user-edit', command: () => {
          this.updateNode();
        }, styleClass: 'update_custom'
      },
      {
        label: 'Delete', icon: 'pi pi-trash', command: (event: any) => {
          this.deleteDesignation(event.originalEvent);
        }, styleClass: 'delete_custom'
      }
    ]
  }


  onContextMenuHide() {
    this.isOpenContextMenu = false;
  }

  onNodeRightClickMenu(event: MouseEvent, node: TreeNode, contextmenu: ContextMenu) {
    this.selectedNode = node;
    //console.log('node', node);
    contextmenu.show(event);
    this.isOpenContextMenu = event.isTrusted; //true
    event.preventDefault();
  }


  getAllDesignationsList() {
    this.ngxLoader.start();
    this.designationService.findAll().subscribe((resp: any) => {
      this.designationList = resp.data;
      this.ngxLoader.stop();
    });
  }

  // getDesignationDetails(designationId:any,content:any) {
  //   this.designationService.findById(designationId).subscribe((response: ApiResponse) => {


  //     this.selectedDesignation = response.data;
  //     this.modalService.open(content);
  //     this.designationUpdateForm.patchValue({
  //     designationName: this.selectedDesignation.name,
  //     orcPercentage: this.selectedDesignation.orc,
  //     designationCode: this.selectedDesignation.code,
  //   });
  //   });
  // }

  /* when clear the modal */
  onModalClose() {
    this.isSubmitted = false;
    this.designationAddForm.reset();
    this.submittedData = false;
    this.formErrors = [];
  }


  getTreeData() {
    this.ngxLoader.start();
    this.designationService.getTree().subscribe((resp: any) => {
      this.designationData = resp.data;
      this.ngxLoader.stop();
    });
  }
  /*  onNodeSelect(event: any, content: any) {
     // event.preventDefault();
      // this.getDesignationDetails(event.node.data.id,content);
  
      this.selectedId = event.node.data.id;
      this.selectedDesignation = this.designationList.find((item: any) => item.id === this.selectedId);
      this.superiorDesignation = this.designationList.find((item: any) => item.id === this.selectedDesignation.parent_id);
  
      this.designationUpdateForm.patchValue({
        designationName: this.selectedDesignation.name,
        superiorDesignation: this.superiorDesignation,
        orcPercentage: this.selectedDesignation.orc,
        designationCode: this.selectedDesignation.code,
      });
      this.modalService.open(content);
      // console.log("yooooooooooooo",this.selectedDesignation);
    } */

  updateNode() {
    if (!this.selectedNode) {
      return;
    }

    //this.openDesignationModal(this.selectedNode) //reset form
    this.formErrors = [];
    const node = this.selectedNode;
    this.selectedId = node.data.id;
    this.selectedDesignation = this.designationList.find((item: any) => item.id === this.selectedId);
    this.superiorDesignation = this.designationList.find((item: any) => item.id === this.selectedDesignation.parent_id);
    this.designationUpdateForm.patchValue({
      designationName: this.selectedDesignation.name,
      superiorDesignation: this.superiorDesignation,
      orcPercentage: this.selectedDesignation.orc,
      designationCode: this.selectedDesignation.code,
    });
    console.log('selectDesignation', this.designationUpdateForm);

    const superDesignation = this.designationUpdateForm.get('superiorDesignation');
    if (this.selectedDesignation.code == 'CHAIRMAN') {
      //superDesignation?.disable();
      this.reportingSuperior = true;
    } else {
      this.reportingSuperior = false;
    }

    (this.selectedDesignation.code == 'CHAIRMAN') ?
      superDesignation?.clearValidators() :
      superDesignation?.setValidators(Validators.required);
    superDesignation?.updateValueAndValidity();

    this.modalService.open(this.editDataModal);
  }


  /*   onNodeRightClick(event: MouseEvent, node: any, content: any) {
      event.preventDefault();
  
       this.selectedId = node.data.id;
       this.selectedDesignation = this.designationList.find((item: any) => item.id === this.selectedId);
       this.superiorDesignation = this.designationList.find((item: any) => item.id === this.selectedDesignation.parent_id);
   
       this.designationUpdateForm.patchValue({
         designationName: this.selectedDesignation.name,
         superiorDesignation: this.superiorDesignation,
         orcPercentage: this.selectedDesignation.orc,
         designationCode: this.selectedDesignation.code,
       });
   
       this.modalService.open(content); 
    } */

  openDesignationModal(content: any) {
    const modalOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
    };

    this.modalRef = this.modalService.open(content, modalOptions);
    this.modalRef.dismissed.subscribe((reason => {
      this.isSubmitted = false;
      this.formErrors = [];
      this.designationAddForm.reset();
    }))
  }

  // check the error key after boolean
  hasErrorFormCheck(key: string): boolean {
    return this.formErrors.some((err => err.key === key)); // check only boolean
  }

  //filter the form array and check
  filterErrorsByKey(key: string): { key: string, value: string }[] {
    return this.formErrors.filter(error => error.key === key); // return new array
  }

  createNewDesignation() {
    this.isSubmitted = true;

    if (this.designationAddForm.valid) {
      this.submittedData = true;
      this.ngxLoader.start();
      const data = {
        parent_id: this.designationAddForm.value.superiorDesignation.id,
        name: this.designationAddForm.value.designationName,
        orc: this.designationAddForm.value.orcPercentage,
        code: this.designationAddForm.value.designationCode,
      };

      this.designationService.create(data).subscribe({
        next: (res: any) => {
          this.submittedData = false;
          this.isSubmitted = false;
          this.designationAddForm.reset();
          this.modalService.dismissAll("close");
          this.getTreeData();
          this.toastr.success(res.message ? res.message : "Designation added successfully", 'Success');
          this.ngxLoader.stop();
        },
        error: (error: HttpErrorResponse) => {
          this.isSubmitted = false;
          this.submittedData = false;
          this.formErrors = [];
          if (error.status === 422 && error.error) {
            console.log('Only error :', error.error);

            for (const [key, value] of Object.entries(error?.error)) {// loop the error object
              if (typeof value === 'object' && value != null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                  this.formErrors.push({ key: nestedKey, value: nestedValue as string })
                  this.submittedData = false;
                }
                //  this.scrollToFirstError();
              } else {
                this.formErrors.push({ key: key, value: value as string })
                this.submittedData = false;
                //this.scrollToFirstError();
              }
            }
          } else {
            this.toastr.error(error.message || "Something went wrong, Please try again later.", "Error!");
          }
          this.ngxLoader.stop();
        },
      });
    }
    this.isSubmitted = true;
  }

  EditDesignation() {
    this.isSubmitted = true;

    if (this.designationUpdateForm.valid) {
      this.submittedData = true;
      this.ngxLoader.start();
      const data = {
        parent_id: this.designationUpdateForm.value.superiorDesignation?.id,
        name: this.designationUpdateForm.value.designationName,
        orc: this.designationUpdateForm.value.orcPercentage,
        code: this.designationUpdateForm.value.designationCode,
      };
      this.designationService.update(this.selectedDesignation.id, data).subscribe({
        next: (res: any) => {
          const index = this.designationList.findIndex((item: any) => item.id === this.selectedDesignation.id);
          if (index !== -1) {
            this.designationList[index] = { ...this.designationList[index], ...data };
          }
          this.updateTreeNode(this.designationData, this.selectedDesignation.id, data);


          this.submittedData = false;
          this.isSubmitted = false;
          this.designationUpdateForm.reset();
          this.modalService.dismissAll("close");
          this.getTreeData();
          this.toastr.success(res.message ? res.message : "Designation updated successfully", 'Success');
          this.ngxLoader.stop();
        },
        error: (error: HttpErrorResponse) => {
          this.submittedData = false;
          this.isSubmitted = false;
          this.formErrors = [];
          if (error.status === 422 && error.error) {
            console.log('Only error :', error.error);

            for (const [key, value] of Object.entries(error?.error)) {// loop the error object
              if (typeof value === 'object' && value != null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                  this.formErrors.push({ key: nestedKey, value: nestedValue as string })
                  this.submittedData = false;
                }
                //  this.scrollToFirstError();
              } else {
                this.formErrors.push({ key: key, value: value as string })
                this.submittedData = false;
                //this.scrollToFirstError();
              }
            }
          } else {
            this.toastr.error(error.message || "Something went wrong, Please try again later.", "Error!");
          }
          this.ngxLoader.stop();
        },
      });
    }
  }

  /* update tree node */
  updateTreeNode(nodes: TreeNode[], id: any, data: any) {
    for (let node of nodes) {
      if (node.data.id === id) {
        node.data = { ...node.data, ...data };
        return true;
      }
      if (node.children) {
        const found = this.updateTreeNode(node.children, id, data);
        if (found) return true;
      }
    }
    return false;
  }



  deleteDesignation(event: Event) {
    console.log('event', this.selectedNode);

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: 'Are you sure?',
      message: ` ${this.selectedNode?.data.name}`,
      icon: 'pi pi-question',

      accept: () => {
        this.accept(this.selectedNode?.data.id);

      },
      reject: () => {
        this.reject();
      }
    });
  }

  accept(id: any): void {
    this.ngxLoader.start();
    this.selectedId = id;
    console.log('selected id in designation', this.selectedId);

    this.designationService.delete(this.selectedId).subscribe({
      next: (response: ApiResponse) => {
        this.getTreeData();
        this.designationUpdateForm.reset();
        this.modalService.dismissAll("close");
        this.toastr.success('Designation Deleted', 'Success');
        this.ngxLoader.stop();
      },
      error: (e: HttpErrorResponse) => {
        //console.log('yoo error', e)
        // this.getAllFiles(this.parent_id, this.category_name);
        //this.toastr.error('Something Went wrong', 'error');
        this.ngxLoader.stop();
      },
    });
    this.confirmationService.close();
  }

  reject(): void {
    this.confirmationService.close();
  }
}
