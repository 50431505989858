import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanService } from 'src/app/services/plans.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { QuotationService } from 'src/app/services/quotation.service';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagModule } from 'primeng/tag';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxExtendedPdfViewerModule, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { FeatherModule } from 'angular-feather';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OverlayPanelModule } from 'primeng/overlaypanel';


@Component({
  selector: 'app-all',
  standalone: true,
  imports: [CommonModule, SharedModule, ButtonModule, ReactiveFormsModule, TagModule, ProgressBarModule, TableModule, RouterModule, FormsModule, NgxExtendedPdfViewerModule, FeatherModule, OverlayPanelModule],
  templateUrl: './all.component.html',
  styleUrl: './all.component.scss'
})
export class AllComponent {


  breadCrumbItems!: Array<{}>;
  filteredRequests: any;
  searchValue: string = "";
  quotationsList: any;
  quotationId: any;
  viewedQuotation: any;
  isLoading: boolean = false;
  selectedFileB64: any;
  selectedFilePath: any;
  submittedData: boolean = false;
  url: any = '';
  ///quotationNumbers: any;
  quotationNumbers: any[] = [];


  private modalService = inject(NgbModal);
  private quotationService = inject(QuotationService);
  private toastr = inject(ToastrService);
  private ngxLoader = inject(NgxUiLoaderService);

  constructor() {
    pdfDefaultOptions.assetsFolder = 'assets';
  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Quotations", active: true },
    ];

    this.getAllQuotations();
  }


  /*  openOverLayModal(event: Event) {
     op.toggle(event);
     console.log(op);
 
   } */
  openOverLayModal(event: any, op: any, quotations: any[]) {
    const qtList = quotations.map(quotation => quotation.quotation_number);
    this.quotationNumbers = qtList;
    op.toggle(event); // Use toggle or show method


  }


  getAllQuotations() {
    this.ngxLoader.start();
    this.quotationService.findAllRequestQuotation().subscribe((resp: any) => {
      //console.log(resp);
      this.quotationsList = resp.data;
      this.filteredRequests = resp.data;
      this.ngxLoader.stop();
    });
  }

  openQuotationViewModel(content: any, id: any) {
    this.viewedQuotation = this.quotationsList.find((item: any) => item.id === id);
    //console.log("quotation", this.viewedQuotation);
    this.quotationId = id;
    this.fetchPdfFile(id);
    this.modalService.open(content, {
      size: "xl",
      windowClass: "modal-holder",
    });
  }

  getQuotationNumbers(quotations: any[]): any {
    //const qtList = quotations.map(quotation => quotation.quotation_number).join(', ');
    //console.log('List', qtList);
    // this.quotationNumbers = qtList;
    // return qtList;
  }

  fetchPdfFile(fileId: string): void {
    this.ngxLoader.start();
    this.quotationService.getPdfFile(fileId).subscribe({
      next: (response: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);

        reader.onload = (event: any) => {
          let path = event.target == null ? '' : event.target.result;
          this.selectedFilePath = path as string;
          this.selectedFileB64 = this.selectedFilePath.split(",")[1];
        }
        this.ngxLoader.stop()
      },
      error: (error: any) => {
        console.error('Error fetching PDF:', error);
        this.ngxLoader.stop()
      }
    });
  }

  filterRequests() {
    if (!this.searchValue) return this.filteredRequests = this.quotationsList;
    this.filteredRequests = this.quotationsList.filter((request: any) => {
      return (
        request?.first_name?.toLowerCase().includes(this.searchValue.toLowerCase())

      )
    }
    );
  }

  onClickDownload() {
    this.submittedData = true;
    this.ngxLoader.start();
    this.quotationService.getPdfFile(this.quotationId).subscribe({
      next: (response: Blob) => {
        const blob = new Blob([response], { type: "application/pdf" });
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "quotation_" + this.viewedQuotation.nic + ".pdf"; // Specify filename here with .pdf extension
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);

        this.submittedData = false;
        this.ngxLoader.stop();
      },
      error: (error: any) => {
        this.submittedData = false;
        console.error('Error fetching PDF:', error);
        this.ngxLoader.stop();
      }
    });
  }
}
