import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Inject,
  ViewChild,
  TemplateRef,
  inject,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { EventService } from "../../core/services/event.service";

//Logout
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { Router } from "@angular/router";
import { TokenStorageService } from "../../core/services/token-storage.service";

// Language
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { allNotification, messages } from "./data";
import { CartModel } from "./topbar.model";
import { cartData } from "./data";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "src/app/services/customer.service";
import { UserProfileService } from "src/app/services/profile.service";
import { ApiResponse } from "src/app/core/models/api-response.model";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  messages: any;
  element: any;
  mode: string | undefined;
  @Output() mobileMenuButtonClicked = new EventEmitter();
  allnotifications: any;
  flagvalue: any;
  valueset: any;
  countryName: any;
  cookieValue: any;
  userData: any;
  cartData!: CartModel[];
  total = 0;
  cart_length: any = 0;
  totalNotify: number = 0;
  newNotify: number = 0;
  readNotify: number = 0;
  isDropdownOpen = false;
  @ViewChild("removenotification") removenotification!: TemplateRef<any>;
  notifyId: any;
  userDisplayName: string = "U";
  switchBranchVisible: boolean = false;
  userDisplayRole: string = "User";
  isUserBranchActivate: any;
  currentLoginBranch: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private eventService: EventService,
    public languageService: LanguageService,
    private modalService: NgbModal,
    public _cookiesService: CookieService,
    public translate: TranslateService,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private router: Router,
    private TokenStorageService: TokenStorageService
  ) { }

  private profileService = inject(UserProfileService);

  ngOnInit(): void {
    this.userData = this.TokenStorageService.getUser();

    // const switchBranchRef = JSON.parse(localStorage.getItem('currentActiveBranch')!);
    // if (switchBranchRef != null) {
    //   this.switchBranches = switchBranchRef;
    //   console.log("back switch", this.switchBranches);
    // }
    // this.onImageUpdate(event);

    this.processUserDisplayName();
    this.setTheHomePageLabel();
    this.setImageForTopBar();

    this.element = document.documentElement;

    // Cookies wise Language set
    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.svg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    // Fetch Data
    this.allnotifications = allNotification;

    this.messages = messages;
    this.cartData = cartData;
    this.cart_length = this.cartData.length;
    this.cartData.forEach((item) => {
      var item_price = item.quantity * item.price;
      this.total += item_price;
    });
  }
  selectedImage: string = ''
  setImageForTopBar() {
    //let isImage = localStorage.getItem('userImage')
    //if (isImage) this.selectedImage = isImage;
  }

  backToSwitchBranch() {
    this.router.navigateByUrl("/switch-branches");
  }
  /* processUserDisplayName() {
    // let name = '';
    // let role = 'User';
    const userData = JSON.parse(localStorage.getItem('currentUser')!)
    //const usesr=localStorage.getItem('currentUser');
    console.log('This user Image2-------', this.userData);
    // if (userData.user) {
    //   if (userData.user) {
    //     const user = userData.user;
    //     if (user.first_name) name += user.first_name;
    //     if (user.last_name) name += ' '+user.last_name;
    //     if (user.role) role = user.role;
    //   } else {
    //     name = 'User';
    //   }
    // } else {
    //   name = 'User';
    // }

    this.userDisplayName = userData.user.first_name ? userData.user.first_name : '';
    console.log('This user Image', this.userDisplayName);

    this.userDisplayRole = userData.user.role ? userData.user.role : '';
  } */

  setTheHomePageLabel() {
    const currentPage = JSON.parse(
      localStorage.getItem("currentActiveBranch")!
    );
    //console.log('currentPage', currentPage);
    if (currentPage != null) {
      //console.log('currentPage', this.isUserBranchActivate);
      this.isUserBranchActivate = currentPage;

      // Find the active branch where `is_current_branch === true`
      const activeBranch = this.isUserBranchActivate.find(
        (branch: { is_current_branch: boolean }) =>
          branch.is_current_branch === true
      );

      if (activeBranch) {
        this.currentLoginBranch = activeBranch.branch_name;
        // Do something with the active branch
        //console.log('Active Branch:', activeBranch);

        // For example, you could set a label or perform further actions
      } else {
        console.log("No active branch found.");
      }
    }
  }

  processUserDisplayName() {
    const userData = JSON.parse(localStorage.getItem("currentUser")!);
    // this.selectedImage = userData.user.media_array.paths.medium;

    //branch nullable check
    if (userData.user_branches && userData.user_branches.length > 0) {
      this.switchBranchVisible = true;
    } else {
      this.switchBranchVisible = false;
    }

    if (userData && userData.user && userData.authorization) {
      const user = userData.user;
      const role = userData.authorization;

      this.userDisplayName = "";
      if (user.first_name) this.userDisplayName += user.first_name;

      // If no name parts are available, default to 'User'
      if (!this.userDisplayName.trim()) {
        this.userDisplayName = "User";
      }
      this.userDisplayRole = "";
      if (role.role_name) this.userDisplayRole += role.role_name;

      if (!this.userDisplayRole.trim()) {
        this.userDisplayRole = "User";
      }
      // Set the role if available, otherwise default to 'User'
    } else {
      this.userDisplayName = "User";
      this.userDisplayRole = "User";
    }

    //console.log('This user Image:', this.userDisplayName);
    //console.log('This user Role:', this.userDisplayRole);
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    document.querySelector(".hamburger-icon")?.classList.toggle("open");
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }
  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    // this.submitted = false;
    this.modalService.open(content, { centered: true });
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  changeMode(mode: string) {
    this.mode = mode;
    this.eventService.broadcast("changeMode", mode);

    switch (mode) {
      case "light":
        document.documentElement.setAttribute("data-bs-theme", "light");
        break;
      case "dark":
        document.documentElement.setAttribute("data-bs-theme", "dark");
        break;
      default:
        document.documentElement.setAttribute("data-bs-theme", "light");
        break;
    }
  }

  /***
   * Language Listing
   */
  listLang = [
    { text: "English", flag: "assets/images/flags/us.svg", lang: "en" },
    { text: "Española", flag: "assets/images/flags/spain.svg", lang: "es" },
    { text: "Deutsche", flag: "assets/images/flags/germany.svg", lang: "de" },
    { text: "Italiana", flag: "assets/images/flags/italy.svg", lang: "it" },
    { text: "русский", flag: "assets/images/flags/russia.svg", lang: "ru" },
    { text: "中国人", flag: "assets/images/flags/china.svg", lang: "ch" },
    { text: "français", flag: "assets/images/flags/french.svg", lang: "fr" },
    { text: "Arabic", flag: "assets/images/flags/ar.svg", lang: "ar" },
  ];

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(["/auth/login"]);
  }

  // windowScroll() {
  //   if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
  //     (document.getElementById("back-to-top") as HTMLElement).style.display = "block";
  //     document.getElementById('page-topbar')?.classList.add('topbar-shadow');
  //   } else {
  //     (document.getElementById("back-to-top") as HTMLElement).style.display = "none";
  //     document.getElementById('page-topbar')?.classList.remove('topbar-shadow');
  //   }
  // }

  // Delete Item
  deleteItem(event: any, id: any) {
    var price = event.target
      .closest(".dropdown-item")
      .querySelector(".item_price").innerHTML;
    var Total_price = this.total - price;
    this.total = Total_price;
    this.cart_length = this.cart_length - 1;
    this.total > 1
      ? ((document.getElementById("empty-cart") as HTMLElement).style.display =
        "none")
      : ((document.getElementById("empty-cart") as HTMLElement).style.display =
        "block");
    document.getElementById("item_" + id)?.remove();
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();
    if (this.isDropdownOpen) {
      this.isDropdownOpen = false;
    } else {
      this.isDropdownOpen = true;
    }
  }
  // Search Topbar
  Search() {
    var searchOptions = document.getElementById(
      "search-close-options"
    ) as HTMLAreaElement;
    var dropdown = document.getElementById(
      "search-dropdown"
    ) as HTMLAreaElement;
    var input: any,
      filter: any,
      ul: any,
      li: any,
      a: any | undefined,
      i: any,
      txtValue: any;
    input = document.getElementById("search-options") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    var inputLength = filter.length;

    if (inputLength > 0) {
      dropdown.classList.add("show");
      searchOptions.classList.remove("d-none");
      var inputVal = input.value.toUpperCase();
      var notifyItem = document.getElementsByClassName("notify-item");

      Array.from(notifyItem).forEach(function (element: any) {
        var notifiTxt = "";
        if (element.querySelector("h6")) {
          var spantext = element
            .getElementsByTagName("span")[0]
            .innerText.toLowerCase();
          var name = element.querySelector("h6").innerText.toLowerCase();
          if (name.includes(inputVal)) {
            notifiTxt = name;
          } else {
            notifiTxt = spantext;
          }
        } else if (element.getElementsByTagName("span")) {
          notifiTxt = element
            .getElementsByTagName("span")[0]
            .innerText.toLowerCase();
        }
        if (notifiTxt)
          element.style.display = notifiTxt.includes(inputVal)
            ? "block"
            : "none";
      });
    } else {
      dropdown.classList.remove("show");
      searchOptions.classList.add("d-none");
    }
  }

  /**
   * Search Close Btn
   */
  closeBtn() {
    var searchOptions = document.getElementById(
      "search-close-options"
    ) as HTMLAreaElement;
    var dropdown = document.getElementById(
      "search-dropdown"
    ) as HTMLAreaElement;
    var searchInputReponsive = document.getElementById(
      "search-options"
    ) as HTMLInputElement;
    dropdown.classList.remove("show");
    searchOptions.classList.add("d-none");
    searchInputReponsive.value = "";
  }

  // Remove Notification
  checkedValGet: any[] = [];
  onCheckboxChange(event: any, id: any) {
    this.notifyId = id;
    var result;
    if (id == "1") {
      var checkedVal: any[] = [];
      for (var i = 0; i < this.allnotifications.length; i++) {
        if (this.allnotifications[i].state == true) {
          result = this.allnotifications[i].id;
          checkedVal.push(result);
        }
      }
      this.checkedValGet = checkedVal;
    } else {
      var checkedVal: any[] = [];
      for (var i = 0; i < this.messages.length; i++) {
        if (this.messages[i].state == true) {
          result = this.messages[i].id;
          checkedVal.push(result);
        }
      }
      console.log(checkedVal);
      this.checkedValGet = checkedVal;
    }
    checkedVal.length > 0
      ? ((
        document.getElementById("notification-actions") as HTMLElement
      ).style.display = "block")
      : ((
        document.getElementById("notification-actions") as HTMLElement
      ).style.display = "none");
  }

  notificationDelete() {
    if (this.notifyId == "1") {
      for (var i = 0; i < this.checkedValGet.length; i++) {
        for (var j = 0; j < this.allnotifications.length; j++) {
          if (this.allnotifications[j].id == this.checkedValGet[i]) {
            this.allnotifications.splice(j, 1);
          }
        }
      }
    } else {
      for (var i = 0; i < this.checkedValGet.length; i++) {
        for (var j = 0; j < this.messages.length; j++) {
          if (this.messages[j].id == this.checkedValGet[i]) {
            this.messages.splice(j, 1);
          }
        }
      }
    }
    this.calculatenotification();
    this.modalService.dismissAll();
  }

  calculatenotification() {
    this.totalNotify = 0;
    this.checkedValGet = [];

    this.checkedValGet.length > 0
      ? ((
        document.getElementById("notification-actions") as HTMLElement
      ).style.display = "block")
      : ((
        document.getElementById("notification-actions") as HTMLElement
      ).style.display = "none");
    if (this.totalNotify == 0) {
      document
        .querySelector(".empty-notification-elem")
        ?.classList.remove("d-none");
    }
  }
}
