import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MasterDataService {

    private http = inject(HttpClient);
    private API_URL_PREFIX: string = environment.apiURL + '/admin/master-data';
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    getTitles() {
        return this.http.get(this.API_URL_PREFIX + '/titles', this.httpOptions);
    }

    getBanks() {
        return this.http.get(this.API_URL_PREFIX + '/banks', this.httpOptions);
    }

    getBranches() {
        return this.http.get(this.API_URL_PREFIX + '/branches', this.httpOptions);
    }

    getOccupations() {
        return this.http.get(this.API_URL_PREFIX + '/occupations', this.httpOptions);
    }

    getRaces() {
        return this.http.get(this.API_URL_PREFIX + '/races', this.httpOptions);
    }

    getCountries() {
        return this.http.get(this.API_URL_PREFIX + '/countries', this.httpOptions);
    }

    getBrandOfTrees() {
        return this.http.get(this.API_URL_PREFIX + '/tree-brands', this.httpOptions);
    }

    getNationalities() {
        return this.http.get(this.API_URL_PREFIX + '/nationalities', this.httpOptions);
    }

    getRoles() {
        return this.http.get(this.API_URL_PREFIX + '/roles', this.httpOptions);
    }

}
