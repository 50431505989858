import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMobileNumberMask]',
})
export class MobileNumberMaskDirective {

  constructor(private ngControl: NgControl) { }


  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, ''); // Remove all non-digit characters

    if (value.length > 3 && value.length <= 6) {
      value = value.replace(/(\d{3})(\d{1,3})/, '$1 $2');
    } else if (value.length > 6) {
      value = value.replace(/(\d{3})(\d{3})(\d{1,4})/, '$1 $2 $3');
    }

    this.ngControl.control?.setValue(value);
  }
}
