<p-confirmDialog #cd>
  <ng-template pTemplate="headless" let-message>
    <div
      style="
        background-color: white;
        border-radius: 10px;
        display: flex;
        padding: 2rem;
      "
      class="flex flex-column align-items-center surface-overlay border-round"
    >
      <div
        style="width: 5rem; height: 5rem; border-radius: 60%; display: flex"
        [ngClass]="{
          'icon-approve': status === 'approved',
          'icon-reject': status === 'rejected'
        }"
        class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem"
      >
        <i
          style="color: white; width: 30px; height: 30px; font-size: xx-large"
          class="pi pi-question"
        ></i>
      </div>
      <span
        style="font-weight: bold; font-weight: bold; font-size: 1.4rem"
        class="text-2xl block mb-2 mt-3"
      >
        {{ message.header }}
      </span>
      <p class="mb-0">
        {{ message.message }}
      </p>
      <div *ngIf="status === 'rejected'" class="col-xxl-8 col-md-8 mt-2">
        <input
          type="text"
          appTextInput="nic"
          class="form-control"
          id="basiInput"
          placeholder="Reason"
          [(ngModel)]="reason"
        />
        <small #titleError *ngIf="reasonStatus" class="text-danger m-0">
          Reason is required
        </small>
      </div>
      <div
        style="display: flex; width: 100%; justify-content: center"
        class="mt-4"
      >
        <button
          pButton
          label="Yes"
          (click)="onApprove()"
          [ngClass]="{
            'custom-button-approve': status == 'approved',
            'custom-button-reject': status == 'rejected'
          }"
          class="deleteCustomButtonPlantation p-2"
        ></button>
        <button
          style="
            width: 8rem;
            height: 2.3rem;
            border-radius: 7px;
            margin: 0 5px 0px 5px;
          "
          pButton
          label="Cancel"
          (click)="onCancel()"
          class="p-button-outlined p-2"
        ></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
