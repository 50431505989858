<!--  <app-breadcrumbs
  title="Dashboard"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>  -->
<div class="row">
  <div class="col-lg-12">
    <h2 class="mb-4 text-primary px-2">
      <i-feather style="position: relative;top: -2px;" name="home" class="text-primary icon-dual"></i-feather>
      Dashboard
    </h2>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Top Trends</h5>
              <apx-chart
                [series]="chartOptionsLine.series!"
                [chart]="chartOptionsLine.chart!"
                [xaxis]="chartOptionsLine.xaxis!"
                [stroke]="chartOptionsLine.stroke!"
                [tooltip]="chartOptionsLine.tooltip!"
                [dataLabels]="chartOptionsLine.dataLabels!"
                [legend]="chartOptionsLine.legend!"
                [markers]="chartOptionsLine.markers!"
                [grid]="chartOptionsLine.grid!"
                [yaxis]="chartOptionsLine.yaxis!"
                [title]="chartOptionsLine.title!"
              ></apx-chart>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Top Trends</h5>
              <apx-chart
                [series]="chartOptionsPie.series!"
                [chart]="chartOptionsPie.chart!"
                [labels]="chartOptionsPie.labels!"
                [responsive]="chartOptionsPie.responsive!"
              ></apx-chart>
            </div>
          </div>
        </div>
        <!-- Top Selling Products (Pie Chart) -->
        <div class="col-md-12 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Top Trends</h5>
              <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [stroke]="chartOptions.stroke!"
                [dataLabels]="chartOptions.dataLabels!"
                [plotOptions]="chartOptions.plotOptions!"
                [xaxis]="chartOptions.xaxis!"
                [colors]="chartOptions.colors!"
                [fill]="chartOptions.fill!"
                [yaxis]="chartOptions.yaxis!"
                [legend]="chartOptions.legend!"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
