import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/auth.models';
import { GlobalComponent } from 'src/app/global-component';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserSwitchService {

    //  const AUTH_API = GlobalComponent.AUTH_API;
    private apiUrl = environment.apiURL + '/admin';

    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private http: HttpClient) { }
    /***
     * put switch Branch
     */

    update(payload: Object) {
        return this.http.put(this.apiUrl + '/employee-branches/current-branch', payload, this.httpOptions);
    }






}