import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[removeFullsTop]'
})
export class DisallowFullsTop {

  constructor() { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === '.') {
      event.preventDefault();
    }
  }
}
