<div class="row">
  <div class="col-lg-12">
    <h2 class="mb-4 text-primary">
      <i-feather name="user" class="text-primary icon-dual"></i-feather>
      Customer View
    </h2>
    <div class="card">
      <div class="card-body">
        <div class="my-3 custom-tr">
          <div class="row">
            <div class="col-4">
              <label>Search By Customer Name </label>
              <input
                type="text"
                class="form-control"
                id="txtsearch"
                [(ngModel)]="searchValue"
                placeholder="Search..."
                (input)="filteredRequestsSearch()"
              />
            </div>
            <div class="col-8 text-end"></div>
          </div>
        </div>

        <p-table
          [value]="filteredRequests"
          styleClass="p-datatable"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '90rem' }"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Customer Code</th>
              <th>Customer Full Name</th>
              <th>Nic</th>
              <th class="text-center">Contact Number</th>
              <th class="text-center">Proposal Count</th>
              <th class="text-center">Status</th>
              <!--  <td class="text-center">
                <p-tag
                  [value]="request.status === false ? 'Deactivate' : 'Activate'"
                  [severity]="request.status=== null ? 'danger' : 'success'"
                ></p-tag>
              </td> -->
              <th class="text-end">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-request>
            <tr>
              <td>
                {{ request.customer_number }}
              </td>
              <td>{{ request.full_name }}</td>
              <td>{{ request.nic }}</td>
              <td class="text-center">{{ request.mobile_number ?? "-" }}</td>
              <td class="text-center">{{ request.proposal_count }}</td>
              <td class="text-center">
                <p-tag
                  [value]="request.status === false ? 'Deactivate' : 'Activate'"
                  [severity]="request.status === false ? 'danger' : 'success'"
                ></p-tag>
              </td>
              <td class="text-end">
                <button
                  type="button"
                  class="btn btn-primary btn-md bg-gradient waves-effect waves-light me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#viewModal"
                  data-bs-whatever="Mary"
                  (click)="openViewModel(viewModal, request.id)"
                >
                  <i
                    class="pi pi-file text-white"
                    style="font-size: 1rem; stroke-width: 0.4"
                  ></i>
                  View
                </button>
                <button
                  type="button"
                  class="btn btn-warning btn-md bg-gradient waves-effect waves-light"
                  type="button"
                  (click)="
                    statusChangeCustomer(
                      $event,
                      request?.id,
                      request.full_name,
                      request.status
                    )
                  "
                >
                  <i
                    class="pi pi-cog text-white"
                    style="font-size: 1rem; stroke-width: 0.5"
                  ></i>
                  Edit
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- is empty -->
          <ng-template pTemplate="emptymessage">
            <tr>
              <!-- <td class="text-center" colspan="7">
                <img
                  *ngIf="filteredRequests?.length === 0"
                  src="../../../../assets/images/cgl/other/noData.PNG"
                  alt="nodata"
                />
              </td> -->
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- customer Audience -->
<ng-template #viewModal role="document" let-modal>
  <div class="modal-header pb-1"></div>

  <div class="modal-body modal-xl pt-2">
    <div class="container-fluid">
      <div class="profile-foreground position-relative mx-n4 mt-n4">
        <div class="profile-wid-bg">
          <img
            src="../../../../assets/images/cgl/other/bg_plantsAllView.jpeg"
            alt=""
            class="profile-wid-img"
          />
        </div>
      </div>
      <div class="pt-4 profile-wrapper">
        <div class="row g-4">
          <!--end col-->

          <div class="col">
            <div class="p-2">
              <div class="row m-0">
                <div class="col-10 p-0">
                  <div class="row m-0">
                    <h5 class="text-white mb-1 p-0 mx-2">
                      {{ customerData?.full_name ?? "-" }}
                    </h5>
                  </div>
                </div>
                <div class="col-2 p-0 text-end">
                  <i
                    class="pi pi-times"
                    style="font-size: 27px; color: azure; cursor: pointer"
                    (click)="modal.dismiss('Cross click')"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div>
            <div class="d-flex profile-wrapper">
              <!-- Nav tabs -->
              <ul
                class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                role="tablist"
              ></ul>
              <div class="flex-shrink-0"></div>
            </div>
            <!-- Tab panes -->
            <div class="tab-content pt-3 text-muted">
              <div class="tab-pane active" id="overview-tab" role="tabpanel">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col-xxl-12 col-md-12">
                        <div
                          class="card h-100 shadow"
                          style="margin-bottom: 0px !important"
                        >
                          <div class="card-body">
                            <h5 class="card-title mb-3">Personal Details</h5>
                            <div class="table-responsive">
                              <table class="table table-borderless mb-0">
                                <tbody class="employee-tbl">
                                  <tr>
                                    <th
                                      class="ps-0 maxWidthForEmployeeView"
                                      scope="row"
                                    >
                                      First Name
                                    </th>
                                    <td class="text-muted">
                                      <b>:</b>&nbsp;{{
                                        customerData?.first_name !== null
                                          ? (customerData?.first_name
                                            | capitalize)
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 maxWidthForEmployeeView"
                                      scope="row"
                                    >
                                      Middle Name
                                    </th>
                                    <td class="text-muted">
                                      <b>:</b>&nbsp;{{
                                        (customerData?.middle_name
                                          | capitalize) ?? "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 maxWidthForEmployeeView"
                                      scope="row"
                                    >
                                      Last Name
                                    </th>
                                    <td class="text-muted">
                                      <b>:</b>&nbsp;{{
                                        customerData?.last_name !== null
                                          ? (customerData?.last_name
                                            | capitalize)
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 maxWidthForEmployeeView"
                                      scope="row"
                                    >
                                      NIC
                                    </th>
                                    <td class="text-muted">
                                      <b>:</b>&nbsp;{{
                                        customerData?.nic ?? "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 maxWidthForEmployeeView"
                                      scope="row"
                                    >
                                      Customer Number
                                    </th>
                                    <td class="text-muted">
                                      <b>:</b>&nbsp;{{
                                        customerData?.customer_number ?? "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 maxWidthForEmployeeView"
                                      scope="row"
                                    >
                                      Customer Status
                                    </th>
                                    <td class="text-muted">
                                      <b>:</b>
                                      <span
                                        class="statusView"
                                        [ngStyle]="{
                                          color:
                                            customerData?.status === true
                                              ? '#278E46'
                                              : '#C42B1C'
                                        }"
                                      >
                                        {{
                                          customerData?.status === true
                                            ? "Active"
                                            : "Deactive"
                                        }}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 maxWidthForEmployeeView"
                                      scope="row"
                                    >
                                      Contact Number
                                    </th>
                                    <td class="text-muted">
                                      <b>:</b>
                                      {{
                                        customerData?.mobile_number !== null
                                          ? (customerData?.mobile_number
                                            | capitalize)
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 maxWidthForEmployeeView"
                                      scope="row"
                                    >
                                      Proposal Count
                                    </th>
                                    <td class="text-muted">
                                      <b>:</b>
                                      {{ customerData?.proposal_count ?? "-" }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
            </div>
            <!--end tab-content-->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
</ng-template>

<!-- status manage  -->
<div class="card flex justify-content-center">
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
      <div
        style="
          background-color: white;
          border-radius: 10px;
          display: flex;
          padding: 2rem;
        "
        class="flex flex-column align-items-center surface-overlay border-round"
      >
        <div
          style="
            width: 5rem;
            height: 5rem;
            border-radius: 60%;
            display: flex;
            background-color: #0a3d2c !important;
          "
          class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem"
        >
          <i
            style="color: white; width: 30px; height: 30px; font-size: xx-large"
            class="pi pi-question"
          ></i>
        </div>
        <span
          style="font-weight: 500; font-size: 1rem"
          class="text-2xl block mb-2 mt-3"
        >
          {{ message.header }} {{ message.message }}
        </span>
        <!--  <p class="mb-0">
          <span style="color: #0a3d2c; font-weight: 500">{{
            message.message
          }}</span>
        </p> -->

        <div class="w-100 mt-1">
          <ng-select
            [items]="statusItem"
            id="statusItem"
            [clearable]="false"
            bindLabel="name"
            [(ngModel)]="currentStatus"
            placeholder="Status"
            class="my-dropdown"
            (change)="onChangeStatus($event)"
          >
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>

        <div
          style="display: flex; width: 100%; justify-content: end"
          class="mt-4"
        >
          <!-- <div>
            <ng-select
              [items]="statusItem"
              id="status"
              bindLabel="name"
              placeholder="Status"
              class="my-dropdown"
            >
              <ng-template ng-optgroup-tmp let-item="item">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div> -->
          <!-- <button
            style="
              width: 8rem;
              height: 2.3rem;
              border-radius: 7px;
              margin: 0 5px 0px 5px;
            "
            pButton
            label="Cancel"
            (click)="cd.reject()"
            class="p-button-outlined p-2"
          ></button> -->
          <button
            pButton
            label="Cancel"
            (click)="cd.reject()"
            class="btn btn-light waves-effect waves-light"
          ></button>

          <button
            class="btn editButtonModal mx-1"
            pButton
            label="Edit"
            (click)="cd.accept()"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>
