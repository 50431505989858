import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSwitchService } from 'src/app/core/services/user-switch.service';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-switch-branches',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './switch-branches.component.html',
  styleUrl: './switch-branches.component.scss'
})
export class SwitchBranchesComponent implements OnInit {

  switchBranches: any;
  dummyBranches: { id: string; branch_name: string }[] = [];
  private ngxLoader = inject(NgxUiLoaderService);
  constructor(private switchService: UserSwitchService, private router: Router) {
  }

  ngOnInit(): void {
    const switchBranchRef = JSON.parse(localStorage.getItem('currentActiveBranch')!);
    if (switchBranchRef != null) {
      this.switchBranches = switchBranchRef;
      console.log("back switch", this.switchBranches);
    }
    /*  console.log('Switch Branches', this.switchBranches);
 
     this.dummyBranches.push({ id: '1', branch_name: 'Kesbewa Optional North' });
     this.dummyBranches.push({ id: '1', branch_name: 'Kesbewa Optional North' });
     this.dummyBranches.push({ id: '1', branch_name: 'Kesbewa Optional North' });
     this.dummyBranches.push({ id: '1', branch_name: 'Kesbewa Optional North' });
     this.dummyBranches.push({ id: '1', branch_name: 'Kesbewa Optional North' });
     this.dummyBranches.push({ id: '1', branch_name: 'Kesbewa Optional North' }); */

    //this.switchBranches = this.dummyBranches;
  }

  capitalizeFirstLetter(branchName: string): string {
    if (!branchName) return '';
    return branchName.charAt(0).toUpperCase() + branchName.slice(1);
  }

  goBackToDashBoard() {
    this.router.navigateByUrl('');
  }

  onClickBranch(id: any) {
    const payload = {
      id: id
    }
    this.ngxLoader.start();
    this.switchService.update(payload).subscribe((response: any) => {
      //console.log('updateSuccessfully', response);
      const updatedBranch = JSON.parse(localStorage.getItem('currentActiveBranch')!);
      console.log("new Updated Branch :", updatedBranch);


      if (response && Array.isArray(response.data)) {
        localStorage.setItem('currentActiveBranch', JSON.stringify(response.data));
        this.router.navigate(['']);
      }
      //this.router.navigate(['']);

      // this.router.navigate(['']);
      console.log('Branch Response :', response);
      this.ngxLoader.stop();

    }, (error: HttpErrorResponse) => {
      this.ngxLoader.stop();
      console.error('Error updating', error);
    });
  }
}
